import { PostDataType } from "data/types";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import Card3Small from "containers/BlogPage/Card3Small";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";

const CareersPage = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge color="purple" name="Careers at Alliance Ventura" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Work with us"
          >
            Work with us in Dubai
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Embark on a journey with us at the heart of Dubai's pulsating
            Business Bay, where ambition meets innovation. As you navigate our
            careers page, envision yourself immersed in the dynamic energy of
            Dubai's commercial hub. Here, your professional aspirations align
            with the city's futuristic skyline, offering a canvas to paint your
            career masterpiece.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          Join our team and be part of a narrative that celebrates growth,
          creativity, and the pursuit of excellence. In Dubai, the extraordinary
          is ordinary, and every professional journey is a story of success
          waiting to be written. Let your ambition guide you here, in the heart
          of where dreams take flight.
        </p>
        <p>Explore our open positions!</p>
      </div>
    );
  };

  const fakePostData = [
    {
      id: "12",
      author: {
        id: "456",
        firstName: "Alliance",
        lastName: "Ventura",
        displayName: "Alliance Ventura - Dubai",
        avatar: "https://allianceventura.com/images/apple-touch-icon.png",
        bgImage: "",
        email: "",
        count: 0,
        desc: "Booking Revenue Manager Holiday Homes",
        jobName: "",
        href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=b46d47e233e74fe7&start=0",
        starRating: 0,
      },
      date: "2023-12-20",
      href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=b46d47e233e74fe7&start=0",
      categories: [
        {
          id: "0",
          name: "",
          href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=b46d47e233e74fe7&start=0",
          count: 0,
          thumbnail: "",
          desc: "",
          color: "",
          taxonomy: "category",
          listingType: "experiences",
        },
      ],
      title: "Booking Revenue Manager Holiday Homes",
      featuredImage:
        "https://allianceventura.com/static/img/work-for-us-min.png",
      desc: "AED8,000 - AED10,000 a month",
      commentCount: 0,
      viewdCount: 0,
      readingTime: 0,
      postType: "standard",
    },
    {
      id: "1",
      author: {
        id: "456",
        firstName: "Alliance",
        lastName: "Ventura",
        displayName: "Alliance Ventura - Dubai",
        avatar: "https://allianceventura.com/images/apple-touch-icon.png",
        bgImage: "",
        email: "",
        count: 0,
        desc: "Customer Service Representative Female",
        jobName: "",
        href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=5ef3dd4558df581c&start=0",
        starRating: 0,
      },
      date: "2023-12-20©",
      href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=5ef3dd4558df581c&start=0",
      categories: [
        {
          id: "0",
          name: "",
          href: "https://ae.indeed.com/cmp/Alliance-Ventura/jobs?jk=5ef3dd4558df581c&start=0",
          count: 0,
          thumbnail: "",
          desc: "",
          color: "",
          taxonomy: "category",
          listingType: "experiences",
        },
      ],
      title: "Customer Service Representative Female",
      featuredImage:
        "https://allianceventura.com/static/img/work-for-us-min.png",
      desc: "AED3,000 - AED5,000 a month",
      commentCount: 0,
      viewdCount: 0,
      readingTime: 0,
      postType: "standard",
    },
  ];

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Careers | Alliance Ventura</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.pexels.com/photos/1089645/pexels-photo-1089645.jpeg"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}

        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div
          className={`nc-WidgetPosts rounded-3xl overflow-hidden`}
          data-nc-id="WidgetPosts"
        >
          <WidgetHeading1
            title="🎯 Open Positions"
            //viewAll={{ label: "View all", href: "/#" }}
          />
          <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
            {fakePostData.map((post: any) => (
              <Card3Small
                className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                key={post.id}
                post={post}
              />
            ))}
            {/* <Card3Small
              className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={1}
              post={fakePostData}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
