import { FC, useEffect, useState } from "react";
import ModalSelectDateWCalendar from "components/ModalSelectDateWCalendar";
import {
  CalendarIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import ModalSelectGuests from "components/ModalSelectGuests";
import { GuestsObject } from "../type";
import { formatDate } from "utils/helperFunctions";
import { useNavigate } from "react-router-dom";

const StaySearchFormMobile: FC<{}> = () => {
  const renderForm = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [location, setLocation] = useState<string>("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [startDate, setStartDate] = useState<Date | null>(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [endDate, setEndDate] = useState<Date | null>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (startDate && endDate) {
        setButtonDisabled(false);
      }
    }, [
      startDate,
      endDate,
      guestAdultsInputValue,
      guestChildrenInputValue,
      guestInfantsInputValue,
    ]);

    const handleDateChange = (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    const handleGuestChanges = (value: GuestsObject) => {
      //do nothing
      setGuestAdultsInputValue(value.guestAdults || 0);
      setGuestChildrenInputValue(value.guestChildren || 0);
      setGuestInfantsInputValue(value.guestInfants || 0);
    };

    const handleSearch = () => {
      const totalGuests =
        guestAdultsInputValue +
        guestChildrenInputValue +
        guestInfantsInputValue;

      const buttonSubmitHref = `/search?guests=${
        totalGuests || 1
      }&location=Dubai&start_date=${formatDate(
        startDate
      )}&end_date=${formatDate(endDate)}`;

      navigate(buttonSubmitHref);
    };

    function displayGuestCount() {
      const totalGuests =
        guestAdultsInputValue +
        guestChildrenInputValue +
        guestInfantsInputValue;

      if (totalGuests === 0) {
        return "Add guests";
      } else if (totalGuests === 1) {
        return "1 Guest";
      } else {
        return `${totalGuests} Guests`;
      }
    }

    return (
      <>
        <form>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDateWCalendar
              onDateChange={handleDateChange}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {startDate && endDate
                        ? `${startDate?.toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                          })} - ${endDate?.toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                          })}`
                        : "Check in - Check out"}
                    </span>
                  </div>
                  <CalendarIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              onGuestChange={handleGuestChanges}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {displayGuestCount()}
                      </span>
                    </span>
                  </div>
                  <UserPlusIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
              defaultVal={{
                guestAdults: guestAdultsInputValue,
                guestChildren: guestChildrenInputValue,
                guestInfants: guestInfantsInputValue,
              }}
            />
            <button
              onClick={handleSearch}
              className={`text-left flex-1 p-5 flex justify-between space-x-5 
                ${
                  !buttonDisabled
                    ? "hover:bg-primary-800 dark:hover:bg-primary-800 bg-primary-800 dark:bg-primary-800"
                    : "bg-primary-400 dark:bg-primary-400"
                }`}
              type="button"
              disabled={buttonDisabled}
            >
              <div className="flex flex-col">
                <span className="mt-1.5 text-lg font-semibold text-white">
                  Search your stay
                </span>
                {buttonDisabled && (
                  <span className="flex items-center text-sm text-neutral-400">
                    <InformationCircleIcon className="w-4 h-4 text-white dark:text-neutral-400 mr-2" />
                    Select Check-in and Check-out Dates to Search
                  </span>
                )}
              </div>
              <MagnifyingGlassIcon className="w-7 h-7 text-white dark:text-neutral-400" />
            </button>
          </div>
        </form>
      </>
    );
  };

  return renderForm();
};

export default StaySearchFormMobile;
