import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          About us.
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
        At Alliance Ventura, we epitomize the essence of luxury living and premium experiences in the heart of Dubai. Founded on the principles of excellence, opulence, and unwavering commitment to customer satisfaction, we have emerged as the premier destination for those seeking the ultimate in luxury properties and high-end automobile experiences in this dazzling city.

        </span>
        <ButtonPrimary className="mt-6 sm:mt-11" href="/about">
          Read more
        </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
