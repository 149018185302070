import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Listing } from "types/listing.types";

export interface StayCardHProps {
  className?: string;
  data?: Listing;
  afterPriceTitle?: string;
  from?: boolean;
  params?: any;
}

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data,
  afterPriceTitle = "night",
  from = false,
  params,
}) => {
  //alert(JSON.stringify(data))
  const guests: string | undefined = Array.isArray(params?.guests)
    ? params.guests.join(",")
    : params?.guests ?? "";

  const startDateString: string | undefined =
    params?.start_date instanceof Array
      ? params.start_date[0]
      : params?.start_date;

  const endDateString: string | undefined =
    params?.end_date instanceof Array ? params.end_date[0] : params?.end_date;

  const hrefBuild = params
    ? `/listing/${data?._id}?start_date=${startDateString}&end_date=${endDateString}&guests=${guests}`
    : `/listing/${data?._id}?guests=1`;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={data?.pictures}
          uniqueID={`StayCardH_${data?._id}`}
          href={hrefBuild}
        />
        {/* <BtnLikeIcon isLiked={data?.like} className="absolute right-3 top-3" />
        {data?.saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data?.accommodates} guests
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data && data?.bedrooms > 0 ? data?.bedrooms : 1} {data?.bedrooms === 1 ? <>bed</> : <>beds</>}
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data?.bathrooms} {data?.bathrooms === 1 ? <>bath</> : <>baths</>}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-smoking-ban text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              No smoking
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data && data?.bedrooms > 0 ? data?.bedrooms : 1} {data?.bedrooms === 1 ? <>bedroom</> : <>bedrooms</>}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-wifi text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Wifi
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {data?.propertyType} {data?.address.neighborhood && <>in</>} {data?.address.neighborhood}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">
                {data?.title || <Skeleton />}
              </span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          {/* <StartRating
            reviewCount={data?.reviewCount}
            point={data?.reviewStart}
          /> */}
          <span className="text-base font-semibold text-secondary-500">
            {data && data.prices.basePrice > 0 ? (
              <>
                {from && <>From </>}
                AED {data.prices.basePrice}
              </>
            ) : (
              <>POR</>
            )}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /{afterPriceTitle}
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={hrefBuild ?? "/"} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
