import React from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
export interface MyReservationButtonProps {
  className?: string;
}
const MyReservationButton: React.FC<MyReservationButtonProps> = ({
  className = "",
}) => {
  const nav = useNavigate();
  function _navigate() {
    nav("/my-reservation");
  }
  return (
    <button
      onClick={_navigate}
      className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <span className="sr-only">My Reservation</span>
      <UserCircleIcon className="w-7 h-7" aria-hidden="true" />
    </button>
  );
};

export default MyReservationButton;
