import { useState, useEffect } from 'react';

export interface TokenResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  scope: string;
}

export function useBearerToken(): string {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://allianceventura.com/api/v2/token.php');
        const data: TokenResponse = await response.json();

        // Update token and expiry time
        setToken(data.access_token);
        const expiresAt = new Date().getTime() + data.expires_in * 1000;
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('expires_at', String(expiresAt));
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    // Check if token is already in localStorage and not expired
    const storedExpiresAt = localStorage.getItem('expires_at');
    const storedToken = localStorage.getItem('token');

    if (storedExpiresAt && storedToken) {
      const expiresAt = Number(storedExpiresAt);
      if (expiresAt > new Date().getTime()) {
        // Token is not expired, use it
        setToken(storedToken);
        return;
      }
    }

    // Fetch token if not in localStorage or expired
    fetchToken();

    // Cleanup function
    return () => {};
  }, [token]);

  return token;
}
