import { Fragment, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { format } from "date-fns";
import {
  formatDate,
  getDistrict,
  getDistrictByName,
} from "utils/helperFunctions";

interface HeroSearchForm2MobileProps {
  // Add any necessary props here
}

const getLocationName = (loc: string | null) => {
  const district = getDistrictByName(loc);

  return district?.name ? district.name : "Anywhere";
};

const formatDateString = (dateString: string | null) =>
  dateString
    ? new Date(dateString)
        .toLocaleString("default", { month: "short", day: "numeric" })
        .toUpperCase()
    : "";

const HeroSearchForm2Mobile: React.FC<HeroSearchForm2MobileProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [choosenGuestCount, setChoosenGuestCount] = useState<number>(0);
  const [choosenStartDate, setChoosenStartDate] = useState<Date | null>(null);
  const [choosenEndDate, setChoosenEndDate] = useState<Date | null>(null);
  const [choosenLocation, setChoosenLocation] = useState<string>("");

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const locatie = queryParams.location
    ? `${queryParams.location}`
    : "Where to?";
  const locationName = getLocationName(locatie);

  const guests = queryParams.guests
    ? `${queryParams.guests} Guests`
    : "Add Guests";

  const startDate = queryParams.start_date ? `${queryParams.start_date}` : "";

  const endDate = queryParams.end_date ? `${queryParams.end_date}` : "";

  const dateRange =
    formatDateString(startDate) && endDate
      ? `${formatDateString(startDate)} - ${formatDateString(endDate)}`
      : "Any Week";

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        //className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-4 rounded-full shadow-lg"
        className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        {/* <div className="flex-1 text-left overflow-hidden">
          <span className="flex items-center font-medium text-sm">
            Search your Dubai stay
            <MagnifyingGlassIcon className="w-4 h-4 ml-2" />
          </span>
        </div> */}

        {/* <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">Discover Dubai</span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">Search our best stays</span>
          </div>
        </div> */}

        {/* <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span> */}
      </button>
    );
  };

  const handleLocationChange = (value: string) => {
    setChoosenLocation(value);
  };

  const handleGuestChange = (value: number) => {
    setChoosenGuestCount(value);
  };

  const handleDateChange = (start: Date | null, end: Date | null) => {
    if (start !== choosenStartDate) {
      setChoosenStartDate(start);
    }

    if (end !== choosenEndDate) {
      setChoosenEndDate(end);
    }
  };

  const buttonSubmitHref = `/search?guests=${choosenGuestCount || 1}&location=${
    choosenLocation ? getDistrictByName(choosenLocation)?.slug : "Dubai"
  }&start_date=${formatDate(choosenStartDate)}&end_date=${formatDate(
    choosenEndDate
  )}`;

  const closeModalHref = () => {
    navigate(buttonSubmitHref);
    setShowModal(false);
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="pt-8 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8"></Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm
                                onLocationChange={handleLocationChange}
                                onGuestChange={handleGuestChange}
                                onDateChange={handleDateChange}
                              />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button>
                        <ButtonSubmit
                          onClick={() => {
                            closeModalHref();
                          }}
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
