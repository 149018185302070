import { useQuery } from "react-query";
import axios from "axios";

interface IncomingListingType {
  listing: SingleListingType;
  photos: Photos;
  description: PropertyDescription;
  rating: PropertyRating;
  amenities: Amenties;
}

interface SingleListingType {
  id: number;
  fs_integration_type: number;
  target_id: number;
  currency: string;
  channel_listing_id: number;
  channel_listing_status: any;
  listing_group_id: any;
  group_deposit: any;
  room_type: number;
  listing_type: number;
  instant_booking_old: any;
  instant_booking: string;
  name: string;
  nickname: string;
  security_deposit: any;
  cleaning_fee: number;
  extra_person: number;
  guests_included: number;
  default_daily_price: number;
  weekend_price: any;
  weekly_price_factor: any;
  monthly_price_factor: any;
  non_refundable_factor: any;
  early_bird_days: number;
  early_bird_percent: number;
  last_minute_days: any;
  last_minute_percent: any;
  min_nights: number;
  max_nights: number;
  checkin_start: string;
  checkin_end: string;
  checkout: string;
  cancel_policy: number;
  price_tip: any;
  weekly_tip: any;
  monthly_tip: any;
  max_notice_days: number;
  min_notice_hours: number;
  tags: any;
  thumbnail_file: string;
  updated_at: string;
  is_listed: number;
  country: string;
  countrycode: string;
  state: string;
  city: string;
  city_id: number;
  zipcode: string;
  street: string;
  address: string;
  unit: string;
  neighbourhood: string;
  lat: number;
  lng: number;
  timezone_offset: any;
  timezone: string;
  price_markup: any;
  owner_revenue_formula: any;
  net_revenue_formula: any;
  channel_commission_formula: any;
  total_price_formula: any;
  tax_formula: any;
  cleaning_fee_formula: any;
  city_tax_formula: any;
  master_calendar: number;
  is_cohost: number;
  is_host_active: number;
  children_allowed: number;
  infants_allowed: number;
  pets_allowed: number;
  smoking_allowed: number;
  events_allowed: number;
  no_checkin_days: number;
  no_checkout_days: number;
  children_age_min: any;
  children_age_max: any;
  children_count_max: any;
  quiet_hours: number;
  quiet_hours_from: any;
  quiet_hours_to: any;
  pets_deposit: any;
  pets_fee: any;
  is_leading_listing: any;
  is_completed: number;
  show_airbnb_limitations: number;
  inherit_parent_property: number;
  owner_id: any;
  nights_limit: any;
  tax: any;
  is_breezeway_connected: any;
  listing_status: string;
  property_type_id: number;
  children_not_allowed_details: string;
  permit_or_tax_id: any;
  accounting_module: number;
  airbnb_standard_fees_enabled: number;
  airbnb_occupancy_taxes_enabled: number;
  airbnb_terms_for_adding_taxes_confirmed: number;
  business_tax_id: any;
  tot_registration_id: any;
  pass_through_taxes_collection_type: any;
  eligible_for_pass_through_taxes: any;
  virtual_tour: any;
  police_hotel_code: any;
  police_hotel_name: any;
  is_multi_unit_virtual: any;
  allow_rtb_above_max_nights: number;
  requires_stairs: number;
  requires_stairs_description: any;
  potential_noise: number;
  potential_noise_description: any;
  instant_book_welcome_message: any;
  check_in_option_category: any;
  check_in_option_description: any;
  guest_guide: any;
  access_codes: any;
  payment_processors: any;
  revyoos_widget_id: any;
  service_pms: number;
  service_communication: number;
  service_pms_background: any;
  revenue_target: any;
  high_season_rate: any;
  low_season_rate: any;
  lowest_rate: any;
  back_to_back: any;
  guests_wo_reviews: any;
  guests_unverified: any;
  guest_explanation: any;
  guest_house_rules: any;
  guests_young: any;
  booking_last_minute: any;
  booking_future_period: any;
  hesitate: any;
  booking_additional: any;
  communication_sla: any;
  communication_whitelabel: any;
  communication_phone: any;
  checkin_type: any;
  lockbox_code: any;
  post_cleaning: any;
  post_maintenance: any;
  main_contact_email: any;
  main_contact_phone: any;
  main_contact: any;
  emergency_contact: any;
  checkin_contact: any;
  cleaning_contact: any;
  maintenance_contact: any;
  integration_nickname: string;
  integration_id: number;
  integration_status: string;
  integration_status_cracked: any;
  is_airbnb_official: number;
  integration_read_only: number;
  is_airbnb_cracked: number;
  use_queues: number;
  sync_prices: any;
  sync_photos: any;
  sync_rooms: any;
  sync_amenities: any;
  sync_description: any;
  sync_settings: any;
  person_capacity: number;
  bedrooms: number;
  bathrooms: number;
  bathroom_shared: number;
  area: any;
  beds: number;
  floor: any;
  customer_name: string;
  customer_accounting_module: number;
  meta_listing: any;
  is_unit_pricing_different: any;
  revenue_target_1: any;
  revenue_target_2: any;
  revenue_target_3: any;
  revenue_target_4: any;
  revenue_target_5: any;
  revenue_target_6: any;
  revenue_target_7: any;
  revenue_target_8: any;
  revenue_target_9: any;
  revenue_target_10: any;
  revenue_target_11: any;
  revenue_target_12: any;
  custom_fields: any[];
  users: any[];
  airbnb_link: string;
  parent_listing_id: any;
  customer_id: number;
  sync_category: string;
  ical: string;
  photos: Photos;
  description: PropertyDescription;
}

interface Photo {
  id: number;
  listing_id: number;
  has_thumb: number;
  thumbnail_file: string;
  original_file: string;
  sort_order: number;
}

interface PropertyDescription {
  _id: number;
  fs_listing_id: number;
  lang: string;
  name: string;
  access: string;
  arrival_info: string;
  checkin_place: string;
  description: string;
  directions: string;
  house_manual: string;
  house_rules: string;
  host_interaction: string | null;
  interaction: string;
  landlord_email: string | null;
  landlord_name: string | null;
  landlord_phone: string | null;
  neighborhood_overview: string;
  notes: string;
  payment_details: string | null;
  pickup_service: string | null;
  space: string;
  summary: string;
  transit: string;
}

interface PropertyRating {
  reviews: number;
  rating: number;
  accuracy_rating: number;
  checkin_rating: number;
  clean_rating: number;
  communication_rating: number;
  location_rating: number;
  value_rating: number;
}

type Amenties = Amenity[];

interface Amenity {
  id: number;
  name: string;
  description: string;
}

type Photos = Photo[]; // Array of photos

const API_BASE_URL = "https://allianceventura.com/api/v1/listing/";

// Define the function to fetch a single listing by ID
const fetchSingleListing = async (listingId: string | undefined) => {
  const response = await axios.get<IncomingListingType>(
    `${API_BASE_URL}?listingId=${listingId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

// Define the custom hook
const useFetchSingleListing = (listingId: string | undefined) => {
  return useQuery<IncomingListingType, Error>(
    ["singleListing", listingId],
    () => fetchSingleListing(listingId)
  );
};

export default useFetchSingleListing;
