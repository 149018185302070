import { useQuery } from "react-query";
import axios from "axios";

interface IncomingPriceInfo {
  success: boolean;
  price: Price;
}

interface Price {
  accounting_module: number;
  channel_listing_id: number;
  price_markup: number | null;
  nights: number;
  price: number;
  cleaning_fee: number;
  extra_person: number;
  tax: number | null;
  guests_included: number;
  person_capacity: number;
  symbol: string;
  unicode: string;
  position: string;
  iso_code: string;
  is_listed: number;
  security_deposit: number | null;
  min_nights: number;
  pets_fee: number;
  pets_deposit: number;
  available: boolean;
  extra_person_price: number;
  priceWithMarkup: number;
  total: number;
  priceWithoutDiscount: number;
  monthlyPriceDiscountPercent: number;
  monthlyPriceDiscount: number;
  weeklyPriceDiscountPercent: number;
  weeklyPriceDiscount: number;
  discount: number | null;
  totalPrice: number;
  tax_amount: number;
  totalAfterTax: number;
}

const API_BASE_URL = "https://allianceventura.com/api/v1/price/";

const fetchListingPrice = async (
  listingId: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  guests: number | undefined
) => {
  const formattedStartDate = startDate?.toISOString().split("T")[0] || "";
  const formattedEndDate = endDate?.toISOString().split("T")[0] || "";

  const url = `${API_BASE_URL}?listing_id=${listingId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&guests=${guests}`;

  const response = await axios.get<IncomingPriceInfo>(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useFetchListingPrice = (
  listingId: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  guests: number | undefined
) => {
  return useQuery<IncomingPriceInfo, Error>(
    ["useFetchListingPrice", listingId, startDate, endDate, guests],
    () => fetchListingPrice(listingId, startDate, endDate, guests)
  );
};

export default useFetchListingPrice;
