import { FC, Fragment, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import { capitalizeEveryWord, formatDate } from "utils/helperFunctions";
import queryString from "query-string";
import "react-loading-skeleton/dist/skeleton.css";
import ListingStayDetailPageSkeleton from "./ListingStayDetailPageSkeleton";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";
import useFetchSingleListingV2 from "hooks/useFetchSingleListingV2";
import useFetchListingPrice from "hooks/useFetchListingPrice";
import useFetchListingPriceV2 from "hooks/useFetchListingPriceV2";
import Loader from "components/Loader/Loader";

interface MarkerProps {
  lat: number;
  lng: number;
  text: string;
}

const Marker: React.FC<MarkerProps> = ({ text }) => (
  <div
    style={{
      backgroundColor: "#041C36",
      borderRadius: "50% 50% 50% 0",
      border: "4px solid #fff",
      width: "40px",
      height: "40px",
      transform: "rotate(-45deg)",
    }}
  >
    <></>
  </div>
);

const StayDetailPageContainer: FC<{}> = () => {
  //

  //#region

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Access individual query parameters
  const guests: string | undefined = Array.isArray(queryParams.guests)
    ? queryParams.guests.join(",")
    : queryParams.guests || "";

  const startDateString: string | undefined =
    queryParams.start_date instanceof Array
      ? queryParams.start_date[0] || undefined
      : queryParams.start_date || undefined;

  const endDateString: string | undefined =
    queryParams.end_date instanceof Array
      ? queryParams.end_date[0] || undefined
      : queryParams.end_date || undefined;

  const startDate: Date | undefined = startDateString
    ? new Date(startDateString)
    : undefined;
  const endDate: Date | undefined = endDateString
    ? new Date(endDateString)
    : undefined;

  //#endregion

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const [choosenStartDate, setChoosenStartDate] = useState<Date | null>(
    startDate !== undefined ? startDate : null
  );

  const [choosenEndDate, setChoosenEndDate] = useState<Date | null>(
    endDate !== undefined ? endDate : null
  );
  const [totalchoosenGuests, setTotalGuests] = useState<number | undefined>(
    parseInt(guests) !== undefined ? parseInt(guests) : 1
  );

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const { listingId } = useParams();
  const {
    data: singleListing,
    isLoading,
    error,
  } = useFetchSingleListingV2(listingId);

  const {
    data: listingPricing,
    isLoading: listingPricingLoading,
    error: listingPricingError,
    refetch: refetchListingPrice,
  } = useFetchListingPriceV2(
    listingId,
    choosenStartDate,
    choosenEndDate,
    totalchoosenGuests
  );



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Your logic here
        // You can use the refetch function to manually trigger a refetch
        await refetchListingPrice();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [choosenStartDate, choosenEndDate, totalchoosenGuests]);

  const handleDatesChange = (startDate: Date | null, endDate: Date | null) => {
    setChoosenStartDate(startDate);
    setChoosenEndDate(endDate);
  };

  const handleGuestChange = (totalGuests: number | undefined) => {
    setTotalGuests(totalGuests && totalGuests);
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge
            name={`${singleListing?.address.neighborhood}, ${singleListing?.address.city}`}
          />
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {singleListing?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating
            point={singleListing?.rating.rating}
            reviewCount={singleListing?.rating.reviews}
          /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {singleListing?.address.full}</span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {singleListing?.accommodates}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {singleListing?.beds}{" "}
              <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {singleListing?.bathrooms}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {singleListing?.bedrooms}{" "}
              <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{singleListing?.publicDescription.summary}</span>
          <br />
          <br />
          <span>{singleListing?.publicDescription.space}</span>
          <br /> <br />
          <span>{singleListing?.publicDescription.transit}</span>
        </div>
      </div>
    );
  };

  const renderSectionNeighborhoodOverview = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Neighborhood Overview</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{singleListing?.publicDescription.neighborhood}</span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {singleListing?.amenities
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item} className="flex items-center space-x-3">
                {/* <i className={`text-3xl las ${item.icon}`}></i> */}
                <span className=" ">{capitalizeEveryWord(item)}</span>
              </div>
            ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        {singleListing?.amenities && singleListing?.amenities.length > 12 && (
          <div>
            <ButtonSecondary onClick={openModalAmenities}>
              View more{" "}
              {singleListing?.amenities && singleListing?.amenities.length - 12}{" "}
              amenities
            </ButtonSecondary>
          </div>
        )}

        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {singleListing?.amenities
                      .filter((_, i) => i < 1212)
                      .map((item) => (
                        <div
                          key={item}
                          className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                        >
                          {/* <i
                            className={`text-4xl text-neutral-6000 las ${item.icon}`}
                          ></i> */}
                          <span>{capitalizeEveryWord(item)}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>
                {singleListing?.terms.minNights}
                {singleListing && singleListing?.terms.minNights > 1 ? (
                  <> nights</>
                ) : (
                  <> night</>
                )}
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>{singleListing?.terms.maxNights} nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const defaultProps = {
    center: {
      lat: singleListing?.address.lat,
      lng: singleListing?.address.lng,
    },
    zoom: 11,
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {singleListing?.address.full}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            {/* <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=${singleListing?.listing.lat},${singleListing?.listing.lng}&zoom=15`}
            ></iframe> */}

            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDT67CTvWbc26KYhdEmMUkoCoeNtblYQCw",
              }}
              defaultCenter={{
                lat: singleListing?.address?.lat ?? 0, // Use a default value (e.g., 0) if lat is undefined
                lng: singleListing?.address?.lng ?? 0, // Use a default value (e.g., 0) if lng is undefined
              }}
              defaultZoom={defaultProps.zoom}
            >
              <Marker
                lat={singleListing?.address?.lat ?? 0}
                lng={singleListing?.address?.lng ?? 0}
                text="Your Marker Text"
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            You can cancel the reservation free of charge until 14 days before
            arrival. The total reservation price will be charged if you cancel
            within 14 days of arrival. <br />
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">Security Deposit</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            The damage deposit will be taken at arrival and returned 7 days
            after check-out, subject to a damage inspection of the property.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div>
          <h4 className="text-lg font-semibold">Staying Rules</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            No smoking, pets, parties or events. Check-In is anytime after 3pm.
            Please don't forget to send your passport / valid ID as well as
            those checking in with you. This is a strict requirment of Dubai's
            Economy and Tourism Department (DET)
            <br />
            <br />
            {singleListing?.publicDescription.houseRules}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            Arrival and Departure Times & Info
          </h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>from 3:00 pm</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>before 12:00 pm</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>
                {singleListing?.terms.minNights}
                {singleListing && singleListing?.terms.minNights > 1 ? (
                  <> nights</>
                ) : (
                  <> night</>
                )}
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>{singleListing?.terms.maxNights} nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">

          <span className="text-3xl font-semibold">

            {listingPricing && listingPricing.money ?
              (
                <div>
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                    AED{" "}
                  </span>
                  {listingPricing?.money.fareAccommodation && (
                    <>
                      {listingPricing?.money.balanceDue}
                      <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                        /stay
                      </span>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                    From AED{" "}
                  </span>
                  {singleListing?.prices.basePrice && (
                    <>
                      {singleListing?.prices.basePrice}
                      <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                        /night
                      </span>
                    </>
                  )}
                </div>
              )}
          </span>

          {/* <StartRating
            reviewCount={singleListing?.rating.reviews}
            point={singleListing?.rating.rating}
          /> */}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <StayDatesRangeInput
            className="flex-1 z-[11]"
            startDateX={startDate}
            endDateX={endDate}
            onDatesChange={handleDatesChange}
            listingId={singleListing?._id}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="flex-1"
            guests={totalchoosenGuests}
            onGuestChange={handleGuestChange}
          />
        </form>

        {/* SUM */}
        {listingPricingLoading && <Loader loading={listingPricingLoading} />}
        {listingPricingError && <p>Error: {listingPricingError.message}</p>}

        {listingPricing &&
          listingPricing.money &&
          listingPricing.money.fareAccommodation !== undefined ? (
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                Subtotal
              </span>
              <span>AED {Math.round(listingPricing.money.balanceDue - listingPricing.money.fareCleaning)}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Cleaning charge</span>
              <span>AED {listingPricing.money.fareCleaning}</span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>AED {listingPricing?.money.balanceDue}</span>
            </div>
          </div>
        ) : null}

        {/* SUBMIT */}

        {totalchoosenGuests &&
          singleListing &&
          totalchoosenGuests > singleListing?.accommodates ? (
          <div className="flex justify-between text-red-500 dark:text-neutral-300">
            This property can host max. {singleListing.accommodates}{" "}
            guests
          </div>
        ) : null}

        {totalchoosenGuests && choosenStartDate && choosenEndDate && singleListing && totalchoosenGuests <= singleListing?.accommodates && (
          <>
            <ButtonPrimary
              href={`/checkout?listing=${listingId}&start_date=${formatDate(
                choosenStartDate
              )}&end_date=${formatDate(
                choosenEndDate
              )}&guests=${totalchoosenGuests}`}
            >
              Continue to Checkout
            </ButtonPrimary>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          </>
        )}


      </div>
    );
  };

  if (isLoading) return <ListingStayDetailPageSkeleton />;

  return (
    <div className="nc-ListingStayDetailPage">
      <Helmet>
        <title>{singleListing?.title} - Alliance Ventura</title>
      </Helmet>
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={singleListing?.picture.large}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {singleListing?.pictures
            .filter((_, i) => i >= 1 && i < 5)
            .map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                  }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item.large || ""}
                    alt=""
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSectionNeighborhoodOverview()}
          {/* {renderSection4()} */}
          {/* <SectionDateRange /> */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {renderSection7()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
