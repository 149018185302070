import { FC, ReactNode, useEffect, useState } from "react";
import { StayDataType } from "data/types";
import StayCard from "components/StayCard/StayCard";
import Loader from "components/Loader/Loader";
import Heading from "components/Heading/Heading";
import { useListingsV2 } from "hooks/useFetchListingsV2";
import { Listing } from "types/listing.types";

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = [],
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Alliance Ventura recommends for you",
  headingIsCenter,
  tabs = ["Stays"],
}) => {
  const { data, isLoading } = useListingsV2();
  const [stayListing, setStayListings] = useState<Listing[]>([]);

  useEffect(() => {
    if (data) {
      setStayListings(data.filter((_, i) => i < 4));
    }
  }, [data, isLoading]);

  const renderCard = (data: Listing) => {
    return <StayCard key={data._id} data={data} from />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          stayListing?.map((data) => renderCard(data))
        )}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
