import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loader from "components/Loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import useFetchListingPrice from "hooks/useFetchListingPrice";
import useFetchSingleListing from "hooks/useFetchSingleListing";
import queryString from "query-string";
import ModalSelectGuests from "components/ModalSelectGuests";
import { calculateDaysDifference, formatDate } from "utils/helperFunctions";
import { GuestsObject } from "components/HeroSearchForm/type";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetchListingPriceV2 from "hooks/useFetchListingPriceV2";
import useFetchSingleListingV2 from "hooks/useFetchSingleListingV2";

const MobileFooterSticky = () => {
  const { listingId } = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const startDateString: string | undefined =
    queryParams.start_date instanceof Array
      ? queryParams.start_date[0] || undefined
      : queryParams.start_date || undefined;

  const endDateString: string | undefined =
    queryParams.end_date instanceof Array
      ? queryParams.end_date[0] || undefined
      : queryParams.end_date || undefined;

  const incomingStartDate: Date | null = startDateString
    ? new Date(startDateString)
    : null;
  const incomingEndDate: Date | null = endDateString
    ? new Date(endDateString)
    : null;

  const incomingGuests: string | undefined = Array.isArray(queryParams.guests)
    ? queryParams.guests.join(",")
    : queryParams.guests || "";

  const [startDate, setStartDate] = useState<Date | null>(incomingStartDate);
  const [endDate, setEndDate] = useState<Date | null>(incomingEndDate);
  const [guests, setGuests] = useState<string | null>(incomingGuests);
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState<
    number | undefined
  >(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState<
    number | undefined
  >(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState<
    number | undefined
  >(0);

  const {
    data: listingPrice,
    isLoading: listingPriceIsLoading,
    error: listingPriceError,
  } = useFetchListingPriceV2(listingId, startDate, endDate, 1);

  const {
    data: listingData,
    isLoading: listingIsLoading,
    error: listingError,
  } = useFetchSingleListingV2(listingId);

  const handleGuestChange = (value: GuestsObject) => {
    setGuestAdultsInputValue(value.guestAdults);
    setGuestChildrenInputValue(value.guestChildren);
    setGuestInfantsInputValue(value.guestInfants);
    let total =
      (value.guestAdults ? value.guestAdults : 0) +
      (value.guestChildren ? value.guestChildren : 0) +
      (value.guestInfants ? value.guestInfants : 0);
    setGuests(total.toString());
  };

  const defaultGuests: GuestsObject = {
    guestAdults: guestAdultsInputValue,
    guestChildren: guestChildrenInputValue,
    guestInfants: guestInfantsInputValue,
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start && end) {

      if (listingData) {
        const nightsDifference = calculateDaysDifference(formatDate(start), formatDate(end));
        const isMinNightsNotMet = nightsDifference < listingData.terms.minNights;
        if (isMinNightsNotMet) {
          toast.error(
            `Listing has minimum stay of ${listingData?.terms.minNights} nights`
          );
        } else {
          setStartDate(start);
          setEndDate(end);
        }
      }
    }
  };

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        {listingIsLoading || listingPriceIsLoading ? (
          <Loader loading={true} />
        ) : (
          <div className="">
            {startDate && endDate ? null : (
              <>
                <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                  From
                </span>
              </>
            )}

            <span className="block text-xl font-semibold">
              AED{" "}
              {startDate && endDate && listingPrice
                ? listingPrice?.money.balanceDue
                : listingData?.prices.basePrice}
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                {startDate && endDate ? <>/stay</> : <>/night</>}
              </span>
            </span>

            <div style={{ display: "flex", gap: "10px" }}>
              <ModalSelectDate
                listingId={listingId}
                onDateChange={handleDateChange}
                renderChildren={({ openModal }) => (
                  <span
                    onClick={openModal}
                    className="block text-sm underline font-medium"
                  >
                    {startDate && endDate
                      ? converSelectedDateToString([startDate, endDate])
                      : "Select Dates"}
                  </span>
                )}
              />
              •
              <ModalSelectGuests
                defaultVal={defaultGuests}
                onGuestChange={handleGuestChange}
                renderChildren={({ openModal }) => (
                  <span
                    onClick={openModal}
                    className="block text-sm underline font-medium"
                  >
                    {guests
                      ? `${guests} ${parseInt(guests) > 1 ? "Guests" : "Guest"}`
                      : "Select Guests"}
                  </span>
                )}
              />
            </div>
          </div>
        )}
        {startDate && endDate && guests ? (
          <ModalReserveMobile
            renderChildren={({ openModal }) => (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                //onClick={openModal}
                href={`/checkout?listing=${listingId}&start_date=${formatDate(
                  startDate
                )}&end_date=${formatDate(endDate)}&guests=${guests}`}
              >
                Reserve
              </ButtonPrimary>
            )}
          />
        ) : null}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default MobileFooterSticky;
