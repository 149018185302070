import { useQuery } from "react-query";
import axios from "axios";

interface Tax {
  _id: string;
  unitTypeId: string;
  accountId: string;
  type: string;
  amount: number;
  units: string;
  quantifier: string;
  appliedToAllFees: boolean;
  appliedOnFees: string[];
  isAppliedByDefault: boolean;
  appliedByDefaultOnChannels: string[];
  __v: number;
}

interface Markup {
  amount: number;
  status: string;
  units: string;
}

interface ChannelCommission {
  manual: any[];
}

interface Financials {
  cleaningFee: {
    value: {
      _id: string;
      multiplier: string;
      formula: number;
      valueType: string;
    };
    lastUpdated: string;
  };
  petFee: {
    value: {
      _id: string;
      multiplier: string;
      formula: number;
      valueType: string;
    };
    lastUpdated: string;
  };
}

interface Currency {
  rateDate: string;
  from: string;
  to: string;
  rate: number;
}

interface InvoiceItem {
  title: string;
  amount: number;
  currency: string;
  type: string;
  isLocked: boolean;
  normalType: string;
  isTax?: boolean;
  metadata?: {
    calculated: boolean;
  };
}

interface AutoPaymentsPolicy {
  scheduleTo: {
    timeRelation: {
      relation: string;
      unit: string;
      amount: number;
    };
    reservationEvent: string;
  };
  releaseAuthorizationHold: {
    scheduleTo: {
      timeRelation: {
        relation: string;
        unit: string;
        amount: number;
      };
      reservationEvent: string;
    };
    inUse: boolean;
    chargeType: string;
  };
  _id: string;
  chargeType: string;
  isAuthorizationHold: boolean;
  useGuestCard: boolean;
  amount: number;
}

interface ReleaseAuthorizationHold {
  shouldBeReleasedAt: string;
  status: string;
}

interface Payment {
  policyItemId: string;
  shouldBePaidAt: string | null;
  status: string;
  currency: string;
  event: string;
  isAuthorizationHold: boolean;
  releaseAuthorizationHold: ReleaseAuthorizationHold;
  amount: number;
  paymentMethodId: string;
}

interface Money {
  fareAccommodation: number;
  settingsSnapshot: {
    additionalFees: any[];
    taxes: Tax[];
    markup: Markup;
    channelCommission: ChannelCommission;
    currency: Currency;
    monthlyPriceFactor: number;
    weeklyPriceFactor: number;
    useAccountRevenueShare: boolean;
    useAccountAdditionalFees: boolean;
    securityDepositFee: number;
    cleaningFee: number;
    financials: Financials;
    snapshotCreated: boolean;
  };
  version: number;
  currency: string;
  fareAccommodationAdjusted: number;
  fareCleaning: number;
  invoiceItems: InvoiceItem[];
  fareAccommodationAdjustment: number;
  fareAccommodationDiscount: number;
  hostServiceFee: number;
  hostServiceFeeTax: number;
  hostServiceFeeIncTax: number;
  totalFees: number;
  subTotalPrice: number;
  hostPayout: number;
  hostPayoutUsd: number;
  totalTaxes: number;
  useAccountRevenueShare: boolean;
  netIncomeFormula: string;
  netIncome: number;
  commissionFormula: string;
  commission: number;
  commissionTaxPercentage: number;
  commissionTax: number;
  commissionIncTax: number;
  ownerRevenueFormula: string;
  ownerRevenue: number;
  currencyConversionRateToAccount: number;
  balanceDue: number;
  autoPaymentsPolicy: AutoPaymentsPolicy[];
  payments: Payment[];
  isFullyPaid: boolean;
  paymentsDue: number;
  totalRefunded: number;
  totalPaid: number;
}

interface JSONResponse {
  money: Money;
}


const fetchListingPrice = async (
  listingId: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  guests: number | undefined
) => {
  const formattedStartDate = startDate?.toISOString().split("T")[0] || "";
  const formattedEndDate = endDate?.toISOString().split("T")[0] || "";

  const url = `https://allianceventura.com/api/v2/price.php?listingId=${listingId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&guestCount=${guests}`

  const response = await axios.get<JSONResponse>(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useFetchListingPriceV2 = (
  listingId: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  guests: number | undefined
) => {
  return useQuery<JSONResponse, Error>(
    ["useFetchListingPriceV2", listingId, startDate, endDate, guests],
    () => fetchListingPrice(listingId, startDate, endDate, guests)
  );
};

export default useFetchListingPriceV2;
