import { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import { StayDistrictProps } from "./ListingStaySearchMapPage";
import { useLocation } from "react-router-dom";
import RandomStringDisplay from "components/RandomStringDisplay/RandomStringDisplay";
import queryString from "query-string";
import useFetchSearchListings from "hooks/useFetchSearchListings";
import StayCardHSkeleton from "components/StayCardH/StayCardHSkeleton";
import Loader from "components/Loader/Loader";
import { useListingsV2 } from "hooks/useFetchListingsV2";
import useFetchListingsSearchV2 from "hooks/useFetchListingsSearchV2";
import useFetchListingPriceV2 from "hooks/useFetchListingPriceV2";
import { Listing } from "types/listing.types";

export interface SectionGridHasMapProps {
  districtName: StayDistrictProps | null;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({ districtName }) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [filteredData, setFilteredData] = useState<Listing[] | undefined>([]);
  
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Access individual query parameters
  const guests: string | undefined = Array.isArray(queryParams.guests)
    ? queryParams.guests.join(",")
    : queryParams.guests || "";

  const startDateString: string | undefined =
    queryParams.start_date instanceof Array
      ? queryParams.start_date[0] || undefined
      : queryParams.start_date || undefined;

  const endDateString: string | undefined =
    queryParams.end_date instanceof Array
      ? queryParams.end_date[0] || undefined
      : queryParams.end_date || undefined;

  const endpoint = `https://allianceventura.com/api/v1/available/?start_date=${startDateString}&end_date=${endDateString}&guests=${guests}&d=${districtName?.id}&page=${currentPage}`;
  const { data, isLoading } = useFetchListingsSearchV2(startDateString, endDateString);
  

  const defaultProps = {
    center: {
      lat: 25.0657,
      lng: 55.17128,
    },
    zoom: 11,
  };

  // Parse start and end dates
  const startDate = startDateString ? new Date(startDateString) : null;
  const endDate = endDateString ? new Date(endDateString) : null;

  // Format date range
  const formatDate = (date: Date): string => {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };

  const dateRange =
    startDate && endDate
      ? `${formatDate(startDate)} - ${formatDate(endDate)}`
      : "";

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");

    if (page) {
      // Do something with the page parameter
      const pageNumber = parseInt(page, 10);
      setCurrentPage(pageNumber);
    }
  }, [location.search]);

  //const nPages = data && Math.ceil(data?.total / 10);

  useEffect(() => {
    if (districtName?.id !== 999) {
      setFilteredData(data);
    } else {
      setFilteredData(data);
    }

  }, [districtName, location]);

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={`Stays in ${districtName?.name}`}
            count={data?.length.toString()}
            dateRange={dateRange}
            numerOfGuests={guests}
            loading={isLoading}
          />
          {data?.length === 0 ? (
            <>
              <div
                className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
                role="alert"
              >
                <div className="flex">
                  <div>
                    <p className="font-bold">
                      No available listings found for search.
                    </p>
                    <p className="text-sm">
                      Please try again with different dates or number of guests
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-1 gap-8 mt-5">
                {isLoadingDataRandom ? (
                  <>
                    <StayCardHSkeletonSkeleton />
                    <StayCardHSkeletonSkeleton />
                    <StayCardHSkeletonSkeleton />
                  </>
                ) : (
                  dataRandom?.map((item) => (
                    <div
                      key={item.id}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <StayCardH data={item} from={true} />
                    </div>
                  ))
                )}
              </div> */}
            </>
          ) : null}
          <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
          <div className="grid grid-cols-1 gap-8">
            {isLoading ? (
              <>
                <StayCardHSkeleton />
                <StayCardHSkeleton />
                <StayCardHSkeleton />
              </>
            ) : (
              data?.map((item) => (
                <div
                  key={item._id}
                  onMouseEnter={() => setCurrentHoverID((_) => item._id)}
                  onMouseLeave={() => setCurrentHoverID((_) => -1)}
                >
                  <StayCardH
                    data={item}
                    afterPriceTitle="night"
                    params={queryParams}
                  />
                </div>
              ))
            )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            {/* <Pagination countPage={data?.length} itemsPerPage={10} currentPage={currentPage - 1} /> */}
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div> */}

            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            <GoogleMapReact
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              bootstrapURLKeys={{
                key: "AIzaSyDT67CTvWbc26KYhdEmMUkoCoeNtblYQCw",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {data?.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item._id}
                  key={item._id}
                  lat={item.address.lat}
                  lng={item.address.lng}
                  listing={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
