import { useQuery } from "react-query";
import axios from "axios";
import { Listing, ListingImporter } from "types/listing.types";

const fetchListingSearchV2 = async (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  // const formattedStartDate = startDate?.toISOString().split("T")[0] || "";
  // const formattedEndDate = endDate?.toISOString().split("T")[0] || "";

  const url = `https://allianceventura.com/api/v2/ava.php?startDate=${startDate}&endDate=${endDate}`

  const response = await axios.get<ListingImporter>(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data.results;
};

const useFetchListingsSearchV2 = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  return useQuery<Listing[], Error>(
    ["useFetchListingPriceV2", startDate, endDate],
    () => fetchListingSearchV2(startDate, endDate)
  );
};

export default useFetchListingsSearchV2;
