import { useQuery } from "react-query";
import axios from "axios";

interface IncomingSearchResults {
  success: boolean;
  reservations: Reservation[];
}

interface Reservation {
  id: number;
  target_id: number;
  fs_cancel_policy_id: number;
  initial_listing_id: number;
  currency: string;
  price_per_night: number;
  base_price: number;
  security_price: number | null;
  extra_guest_price: number | null;
  fees_price: number | null;
  extras_price: number | null;
  collected_tax: number;
  host_collected_tax: number;
  cleaning_fee: number;
  channel_commission: number | null;
  owner_commission: number | null;
  service_charge: number | null;
  subtotal: number;
  payout_price: number;
  transaction_fee: number | null;
  sum_refunds: number | null;
  revenue: number;
  owner_revenue: number;
  net_revenue: number;
  channel_reservation_id: string;
  source: string;
  status_code: number;
  status: string;
  status_description: string;
  confirmation_code: string;
  nights: number;
  checkIn: string;
  checkOut: string;
  guests: number;
  adults: number;
  children: number;
  infants: number;
  confirmed_at: string;
  advance_days: number;
  cancel_policy: number;
  planned_arrival: string | null;
  planned_departure: string | null;
  beds_to_be_prepared: number | null;
  notes: string | null;
  cleaning_notes: string | null;
  updated_at: string;
  cancelled_at: string | null;
  is_manual: number;
  added_by: string | null;
  original_reservation_id: number | null;
  extra_info: string | null;
  cancel_penalty: number | null;
  cancel_reason: string | null;
  guest_details: any; // Replace with actual type if available
  price_details: any; // Replace with actual type if available
  addons_price: number | null;
  addons_details: string | null;
  city_tax: number | null;
  extras_price_ex: number | null;
  extras_price_inc: number | null;
  cancellation_fee: number;
  host_penalty_fee: number;
  checked_in_mark: any; // Replace with actual type if available
  checked_out_mark: any; // Replace with actual type if available
  payout_by_channel: number;
  payout_by_channel_changed: number;
  pre_check_in_completed: number;
  pre_check_out_completed: number;
  document_uploaded: number;
  passport_uploaded: number;
  rate_name: string | null;
  created_at: string;
  message_id: number;
  discount_code: string | null;
  discount_id: number | null;
  paid_part: string;
  paid_sum: number | null;
  pricing: any; // Replace with actual type if available
  ev_channel: string | null;
  ev_medium: string | null;
  ev_referring_shop: string | null;
  due: number;
  owners_module: number;
  pets: number;
  language_code: string;
  channel_guest_id: number;
  name: string;
  email: string | null;
  phone: number;
  location: string | null;
  country: string;
  country_code: string | null;
  city: string;
  zip_code: string | null;
  address: string;
  is_verified: number;
  has_facebook: number;
  has_governmentid: number;
  has_email: number;
  has_phone: number;
  reviews: number;
  about: string | null;
  work: string | null;
  languages: string | null;
  img_type: string;
  download: string | null;
  has_thumb: number;
  thumbnail_file: string;
  original_file: string | null;
  zendesk_id: string | null;
  gdpr_parent_customer: number;
  gdpr_cleaned: number;
  property_id: number | null;
  channel_listing_id: number;
  channel_listing_status: string | null;
  listing_group_id: number | null;
  group_deposit: number | null;
  room_type: number;
  listing_type: number;
  instant_booking_old: string | null;
  instant_booking: string;
  can_overbook: number;
  nickname: string;
  security_deposit: number | null;
  extra_person: number;
  guests_included: number;
  default_daily_price: number;
  weekend_price: number | null;
  weekly_price_factor: number | null;
  monthly_price_factor: number | null;
  non_refundable_factor: number;
  early_bird_days: number;
  early_bird_percent: number;
  last_minute_days: number | null;
  last_minute_percent: number | null;
  min_nights: number;
  max_nights: number;
  checkin_start: string;
  checkin_end: string;
  checkout: string;
  price_tip: string | null;
  weekly_tip: string | null;
  monthly_tip: string | null;
  max_notice_days: number;
  min_notice_hours: number;
  turnover_days: number;
  tags: string | null;
  is_active: number;
  init_calendar: number;
  active_since: string | null;
  calendar_updated_at: string;
  minstay_last_update: string | null;
  old_id: number | null;
  is_listed: number;
  active_until: string | null;
  countrycode: string;
  state: string;
  city_id: number;
  zipcode: string;
  street: string;
  unit: string;
  neighbourhood: string;
  lat: number;
  lng: number;
  timezone_offset: string | null;
  timezone: string;
  price_markup: string | null;
  owner_revenue_formula: string | null;
  net_revenue_formula: string | null;
  channel_commission_formula: string | null;
  total_price_formula: string | null;
  tax_formula: string | null;
  cleaning_fee_formula: string | null;
  city_tax_formula: string | null;
  master_calendar: number;
  is_datasync: number;
  is_cohost: number;
  is_host_active: number;
  children_allowed: number;
  infants_allowed: number;
  pets_allowed: number;
  smoking_allowed: number;
  events_allowed: number;
  no_checkin_days: number;
  no_checkout_days: number;
  children_age_min: number | null;
  children_age_max: number | null;
  children_count_max: number | null;
  quiet_hours: number;
  quiet_hours_from: string | null;
  quiet_hours_to: string | null;
  pets_deposit: number | null;
  pets_fee: number | null;
  is_leading_listing: number | null;
  is_completed: number;
  show_airbnb_limitations: number;
  inherit_parent_property: number;
  owner_id: number | null;
  nights_limit: number | null;
  tax: string | null;
  is_breezeway_connected: number | null;
  listing_status: string;
  property_type_id: number;
  children_not_allowed_details: string;
  permit_or_tax_id: string | null;
  accounting_module: number;
  airbnb_standard_fees_enabled: number;
  airbnb_occupancy_taxes_enabled: number;
  airbnb_terms_for_adding_taxes_confirmed: number;
  business_tax_id: string | null;
  tot_registration_id: string | null;
  pass_through_taxes_collection_type: string | null;
  eligible_for_pass_through_taxes: number | null;
  virtual_tour: string | null;
  police_hotel_code: string | null;
  police_hotel_name: string | null;
  is_multi_unit_virtual: number | null;
  allow_rtb_above_max_nights: number;
  requires_stairs: number;
  requires_stairs_description: string | null;
  potential_noise: number;
  potential_noise_description: string | null;
  instant_book_welcome_message: string | null;
  check_in_option_category: string | null;
  check_in_option_description: string | null;
  guest_guide: string | null;
  access_codes: string | null;
  payment_processors: string | null;
  revyoos_widget_id: string | null;
  listing_id: number;
  integration_id: number;
  guest_id: number;
  inbox_id: number;
  tax_amount: number;
}

const API_BASE_URL = "https://allianceventura.com/api/v1/search/";

const fetchSearch = async (
  q: string | undefined,
  type?: string | undefined
) => {
  const url = `${API_BASE_URL}?q=${q}&type=${type}`;

  const response = await axios.get<IncomingSearchResults>(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useFetchSearch = (q: string | undefined, type?: string | undefined) => {
  return useQuery<IncomingSearchResults, Error>(
    ["useFetchSearch", q, (type = "reservations")],
    () => fetchSearch(q, type)
  );
};

export default useFetchSearch;
