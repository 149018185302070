// shuffle data

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function capitalizeEveryWord(input: string): string {
  return input.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function formatDate(inputDate: Date | null): string {
  if (!inputDate) {
    return ""; // or any default value you prefer for null dates
  }

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function calculateDaysDifference(
  startDateStr: string,
  endDateStr: string
): number {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert the time difference to days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // Return the number of days (rounded to remove any partial days)
  return Math.round(daysDifference);
}

type District = {
  id: number;
  name: string;
  slug: string;
};

export function getDistrict(locationParam: string): District | null {
  let district: District | null = null;

  switch (locationParam) {
    case "downtown":
      district = { id: 1, name: "Downtown", slug: "downtown" };
      break;

    case "business-bay":
      district = { id: 2, name: "Business Bay", slug: "business-bay" };
      break;

    case "dubai-hills":
      district = { id: 3, name: "Dubai Hills", slug: "dubai-hills" };
      break;

    case "jvc":
      district = { id: 4, name: "JVC", slug: "jvc" };
      break;

    case "palm-jumeirah":
      district = { id: 5, name: "Palm Jumeirah", slug: "palm-jumeirah" };
      break;

    case "marina":
      district = { id: 6, name: "Marina", slug: "marina" };
      break;

    case "Dubai":
      district = { id: 999, name: "Dubai", slug: "Dubai" };
      break;

    default:
      break;
  }

  return district;
}

const districts: District[] = [
  { id: 1, name: "Downtown", slug: "downtown" },
  { id: 2, name: "Business Bay", slug: "business-bay" },
  { id: 3, name: "Dubai Hills", slug: "dubai-hills" },
  { id: 4, name: "JVC", slug: "jvc" },
  { id: 5, name: "Palm Jumeirah", slug: "palm-jumeirah" },
  { id: 6, name: "Marina", slug: "marina" },
  { id: 999, name: "Dubai", slug: "Dubai" },
];

export function getDistrictById(id: number): District | undefined {
  return districts.find((district) => district.id === id);
}

export function getDistrictByName(name: string | null): District | undefined {
  if (name === null) {
    let d = "Dubai";
    return undefined; // or handle the case as per your requirements
  }

  const normalizedInput = name.toLowerCase();
  const foundDistrict = districts.find(
    (district) => district.name.toLowerCase() === normalizedInput
  );

  return foundDistrict;
}

export function convertDateRange(
  dateString1: string,
  dateString2: string
): string {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const formattedDate1 = date1.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const formattedDate2 = date2.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  return `${formattedDate1} - ${formattedDate2}`;
}

export function convertSingleDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
}

export function convertTime(timeString: string): string {
  const time = new Date(`2000-01-01T${timeString}`);
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}
