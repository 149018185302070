import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Listing, ListingImporter } from 'types/listing.types';
import { useBearerToken } from './useBearerToken';
import { useEffect, useState } from 'react';

async function fetchData(token: string | null): Promise<Listing[]> {
  const options = {
    headers: {
      accept: 'application/json'
    },
  };

  const url = `https://allianceventura.com/api/v2/?endpoint=listings`;

  try {
    const response: AxiosResponse<ListingImporter> = await axios.get(url, options);
    console.log(response.data.count);
    return response.data.results;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export function useListingsV2() {
  const token: string = useBearerToken();
  const storedToken = localStorage.getItem('token');

  const [usedToken, setToken] = useState<string>("");
  const { isLoading, isError, data } = useQuery('listings', async () => {
    if (storedToken) {
      return await fetchData(storedToken);
    }
    return [];
  });

  useEffect(() => {
    setToken(token);
    // Cleanup function
    return () => { };
  }, [token]);

  return { isLoading, isError, data };
}
