import React, { FC, useState } from "react";
import LocationInput, { LocationItem } from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";

const StaySearchForm: FC<{}> = () => {
  const renderForm = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [location, setLocation] = useState<string>("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [startDate, setStartDate] = useState<Date | null>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [endDate, setEndDate] = useState<Date | null>();

    const handleLocationChange = (value: string) => {
      setLocation(value);
    };

    const handleSelectLocation = (item: LocationItem) => {
      setLocation(item.slug);
    };

    const handleDateChange = (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          className="flex-[1.5]"
          onChange={handleLocationChange}
          onLocationSelect={handleSelectLocation}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput
          className="flex-1"
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-1"
          startDate={startDate}
          endDate={endDate}
          location={location}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
