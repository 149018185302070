import { useQuery } from "react-query";
import axios from "axios";
import { Listing } from "types/listing.types";


const API_BASE_URL = "https://allianceventura.com/api/v2/";

// Define the function to fetch a single listing by ID
const fetchSingleListingV2 = async (listingId: string | undefined, token: string | null) => {
  const response = await axios.get<Listing>(
    `${API_BASE_URL}?endpoint=listings/${listingId}&token=${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

// Define the custom hook
const useFetchSingleListingV2 = (listingId: string | undefined) => {
  const storedToken = localStorage.getItem('token');
  return useQuery<Listing, Error>(
    ["singleListing", listingId],
    () => fetchSingleListingV2(listingId, storedToken)
  );
};

export default useFetchSingleListingV2;
