import { FC, useEffect, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export interface ListingStayMapPageProps {
  className?: string;
}

export interface StayDistrictProps {
  id: number;
  name: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  const { propertyname } = useParams();
  const [currentDistrict, setCurrentDistrict] =
    useState<StayDistrictProps | null>(null);

  useEffect(() => {
    switch (propertyname) {
      case "downtown":
        setCurrentDistrict({
          id: 1,
          name: "Downtown",
        });
        break;

      case "business-bay":
        setCurrentDistrict({
          id: 2,
          name: "Business Bay",
        });
        break;

      case "dubai-hills":
        setCurrentDistrict({
          id: 3,
          name: "Dubai Hills",
        });
        break;

      case "jvc":
        setCurrentDistrict({
          id: 4,
          name: "JVC",
        });
        break;

      case "palm-jumeirah":
        setCurrentDistrict({
          id: 5,
          name: "Palm Jumeirah",
        });
        break;

      case "marina":
        setCurrentDistrict({
          id: 3,
          name: "Marina",
        });
        break;

      case "all":
        setCurrentDistrict({
          id: 999,
          name: "Dubai",
        });
        break;

      default:
        break;
    }
  }, [propertyname]);

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Stays in {`${currentDistrict?.name}`} - Alliance Ventura</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container pt-5 lg:pt-8"></div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap districtName={currentDistrict} />
      </div>

      <div className="container overflow-hidden">{/* SECTION 1 */}</div>
    </div>
  );
};

export default ListingStayMapPage;
