import { FC, useEffect, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

export interface ListingStaySearchMapPageProps {
  className?: string;
}

export interface StayDistrictProps {
  id: number;
  name: string;
}

const ListingStaySearchMapPage: FC<ListingStaySearchMapPageProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Access individual query parameters
  const locationParam = queryParams.location || "";

  const [currentDistrict, setCurrentDistrict] =
    useState<StayDistrictProps | null>(null);

  useEffect(() => {
    switch (locationParam) {
      case "downtown":
        setCurrentDistrict({
          id: 1,
          name: "Downtown",
        });
        break;

      case "business-bay":
        setCurrentDistrict({
          id: 2,
          name: "Business Bay",
        });
        break;

      case "dubai-hills":
        setCurrentDistrict({
          id: 3,
          name: "Dubai Hills",
        });
        break;

      case "jvc":
        setCurrentDistrict({
          id: 4,
          name: "JVC",
        });
        break;

      case "palm-jumeirah":
        setCurrentDistrict({
          id: 5,
          name: "Palm Jumeirah",
        });
        break;

      case "marina":
        setCurrentDistrict({
          id: 3,
          name: "Marina",
        });
        break;

      case "Dubai":
        setCurrentDistrict({
          id: 999,
          name: "Dubai",
        });
        break;

      default:
        setCurrentDistrict({
          id: 999,
          name: "Dubai",
        });
        break;
    }
  }, [location]);

  return (
    <div
      className={`nc-ListingStaySearchMapPage relative ${className}`}
      data-nc-id="ListingStaySearchMapPage"
    >
      <Helmet>
        <title>
          Search Results for Stays in {`${currentDistrict?.name}`} - Alliance
          Ventura
        </title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container pt-5 lg:pt-8"></div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap districtName={currentDistrict} />
      </div>

      <div className="container overflow-hidden">{/* SECTION 1 */}</div>
    </div>
  );
};

export default ListingStaySearchMapPage;
