import { FC } from "react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import DetailPagetLayout from "../Layout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StayDetailPageContainer: FC<{}> = () => {
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <Skeleton />

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          <Skeleton />
        </h2>

        {/* 3 */}
        <Skeleton />

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          <Skeleton />
        </h2>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          <Skeleton />
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <Skeleton count={3} />
          <br />

          <Skeleton count={2} />
          <br />
          <Skeleton count={2} />
        </div>
      </div>
    );
  };

  const renderSectionNeighborhoodOverview = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          <Skeleton />
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <Skeleton count={5} />
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <Skeleton height={40} />
        <Skeleton count={3} />
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer ">
            <Skeleton height={"100%"} />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          <div
            key={1}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
              1 >= 3 ? "hidden sm:block" : ""
            }`}
          >
            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
              <Skeleton height={400} width={400} />
            </div>

            {/* OVERLAY */}
            <div
              className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
              //
            />
          </div>

          <div
            key={2}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
              2 >= 3 ? "hidden sm:block" : ""
            }`}
          >
            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
              <Skeleton height={400} width={400} />
            </div>

            {/* OVERLAY */}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer" />
          </div>

          <div
            key={3}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
              3 >= 3 ? "hidden sm:block" : ""
            }`}
          >
            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
              <Skeleton height={400} width={400} />
            </div>

            {/* OVERLAY */}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer" />
          </div>

          <div
            key={4}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
              4 >= 3 ? "hidden sm:block" : ""
            }`}
          >
            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
              <Skeleton height={400} width={400} />
            </div>

            {/* OVERLAY */}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer" />
          </div>

          <button className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10">
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSectionNeighborhoodOverview()}
        </div>
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};
export default function ListingStayDetailPageSkeleton() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
