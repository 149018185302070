import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
        Our commitment to excellence extends beyond our properties and
        automobiles. We are dedicated to creating lasting memories and
        unforgettable moments for our clients. Our team of professionals is
        available around the clock to assist you with your every need, ensuring
        that your stay in Dubai is nothing short of perfection.
        <br />
        <br />
        Whether you are here for business, leisure, or a little bit of both,
        Alliance Ventura is your trusted partner for luxury living and high-end
        mobility in Dubai. Join us on this extraordinary journey and discover
        the true meaning of luxury with Alliance Ventura. Your Dubai dream
        awaits.
        <br />
        <br />
        With an impressive portfolio of lavish properties spread across iconic
        neighborhoods such as Downtown, Business Bay, Palm Jumeirah, Marina, and
        more, we invite you to immerse yourself in the Dubai dream. Our
        meticulously curated selection of luxurious apartments, villas, and
        penthouses offers stunning views, opulent interiors, and world-class
        amenities that cater to your every desire.
        <br />
        <br />
        At Alliance Ventura, we don’t just rent out properties; we craft living
        spaces that embody sophistication, comfort, and exclusivity. Whether
        you’re looking for a place to call home or a retreat for a memorable
        vacation, our properties are designed to exceed your expectations.
      </span>
    </div>
  );
};

export default SectionFounder;
