import { ChangeEvent, FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";
import useFetchListingPrice from "hooks/useFetchListingPrice";
import useFetchSingleListing from "hooks/useFetchSingleListing";
import Skeleton from "react-loading-skeleton";
import NcImage from "shared/NcImage/NcImage";
import "react-loading-skeleton/dist/skeleton.css";
import useFetchSearch from "hooks/useFetchSearch";
import Badge from "shared/Badge/Badge";
import {
  convertDateRange,
  convertSingleDate,
  convertTime,
} from "utils/helperFunctions";
import { useListingsV2 } from "hooks/useFetchListingsV2";

export interface MyReservationPageProps {
  className?: string;
}

interface FormData {
  searchQuery: string;
  email: string;
}

const MyReservationPage: FC<MyReservationPageProps> = ({ className = "" }) => {
  const [searchPressed, setSearchPressed] = useState<boolean>(false);
  const [enableSearchButton, setEnableSearchButton] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormData>({
    searchQuery: "",
    email: "",
  });

  const {
    data: searchData,
    isLoading: searchIsLoading,
    error: searchError,
  } = useFetchSearch(formData.searchQuery);

  const [startDate] = useState<Date | null>(null);
  const [endDate] = useState<Date | null>(null);

  let listingId = "146257";

  const {
    data: listingData,
    isLoading: listingIsLoading,
    error: listingError,
  } = useFetchSingleListing(listingId);

  const { data: v2Listings, isLoading: v2ListingsLoading, isError: v2ListingsError } = useListingsV2();

  useEffect(() => {
    console.log(v2ListingsError);

    return () => {

    };
  }, [v2Listings]);

  const {
    data: listingPrice,
    isLoading: listingPriceIsLoading,
    error: listingPriceError,
  } = useFetchListingPrice(listingId, startDate, endDate, 2);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (formData.email.length > 0 && formData.searchQuery.length > 0) {
      setEnableSearchButton(false);
    }
  };
  const handleSearch = () => {
    setSearchPressed(true);
  };

  const renderContentInput = (errorString?: boolean) => {
    return (
      <div
        className={`nc-MyReservationPage ${className}`}
        data-nc-id="MyReservationPage"
      >
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            My Reservation
          </h2>
          {errorString ? (
            <h3 className="my-20 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-red-900 dark:text-neutral-100 justify-center">
              There was no reservation found with the provided information.
            </h3>
          ) : null}
          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email
                </span>
                <Input
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="example@example.com"
                  className="mt-1"
                  name="email"
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Confirmation Code
                </span>
                <Input
                  type="text"
                  placeholder="XXXXXXX"
                  className="mt-1"
                  onChange={handleInputChange}
                  value={formData.searchQuery}
                  name="searchQuery"
                />
              </label>
              <ButtonPrimary
                disabled={enableSearchButton}
                loading={searchIsLoading && searchPressed}
                onClick={handleSearch}
              >
                Search Reservation
              </ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              You can find your confirmation code in the email that you received
              from us.
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Your Reservation</h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            Reservation #{searchData?.reservations[0].confirmation_code}
          </h3>
          <Badge
            name={
              searchData?.reservations[0].status === "accepted"
                ? searchData?.reservations[0].status_description
                : null
            }
            color="green"
          />
          <Badge
            className="ml-2"
            name={
              searchData?.reservations[0].source
                ? searchData?.reservations[0].source
                : null
            }
            color="pink"
          />
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={searchData?.reservations[0].thumbnail_file} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {searchData?.reservations[0].neighbourhood},{" "}
                  {searchData?.reservations[0].city}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {searchData?.reservations[0].name}
                </span>
              </div>
              {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {searchData?.reservations[0].bed}{" "}
                {searchData?.reservations[0].bedrooms === 1 ? "bed" : "beds"} ·{" "}
                {searchData?.reservations[0].bathrooms}{" "}
                {searchData?.reservations[0].bathrooms === 1 ? "bath" : "baths"}
              </span> */}
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating
                point={listingData?.rating.rating}
                reviewCount={listingData?.rating.reviews}
              />
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {convertDateRange(
                    searchData?.reservations[0].checkIn
                      ? searchData?.reservations[0].checkIn
                      : "",
                    searchData?.reservations[0].checkOut
                      ? searchData?.reservations[0].checkOut
                      : ""
                  )}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {searchData?.reservations[0].guests}{" "}
                  {searchData?.reservations[0].guests === 1
                    ? "Guest"
                    : "Guests"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {searchData?.reservations[0].confirmation_code}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Check in</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {convertSingleDate(
                  searchData?.reservations[0].checkIn
                    ? searchData?.reservations[0].checkIn
                    : ""
                )}{" "}
                from{" "}
                {convertTime(
                  searchData?.reservations[0].checkin_start
                    ? searchData?.reservations[0].checkin_start
                    : ""
                )}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Check out</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {convertSingleDate(
                  searchData?.reservations[0].checkOut
                    ? searchData?.reservations[0].checkOut
                    : ""
                )}{" "}
                before{" "}
                {convertTime(
                  searchData?.reservations[0].checkout
                    ? searchData?.reservations[0].checkout
                    : ""
                )}
              </span>
            </div>
            {/* <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                AED 1000
              </span>
            </div> */}
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {searchData?.reservations[0].source !== "Website"
                  ? `Via partner (${searchData?.reservations[0].source})`
                  : "Credit card (Stripe)"}
              </span>
            </div>
          </div>
        </div>

        <div>
          <ButtonPrimary href="/explore/all">Explore more stays</ButtonPrimary>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          <Skeleton width={300} />
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            {" "}
            <Skeleton width={200} />
          </h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <Skeleton height={400} width={400} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  <Skeleton width={120} />
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  <Skeleton width={250} />
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                <Skeleton width={80} />
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <Skeleton width={30} />
            </div>
          </div>
          <br />
          <Skeleton height={60} />
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            {" "}
            <Skeleton width={300} />
          </h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {" "}
                <Skeleton width={200} />
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {" "}
                <Skeleton width={200} />
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {" "}
                <Skeleton width={200} />
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <Skeleton width={200} />
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">
                {" "}
                <Skeleton width={200} />
              </span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                <Skeleton width={200} />
              </span>
            </div>
          </div>
        </div>

        <div>
          <Skeleton width={200} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>My Reservation | Alliance Ventura</title>
      </Helmet>
      {formData.searchQuery && searchData && searchPressed ? (
        <div
          className={`nc-MyReservationPage ${className}`}
          data-nc-id="MyReservationPage"
        >
          <main className="container mt-11 mb-24 lg:mb-32 ">
            {searchData && searchData.reservations.length > 0 ? (
              <div className="max-w-4xl mx-auto">
                {listingIsLoading || listingPriceIsLoading
                  ? renderSkeleton()
                  : renderContent()}
              </div>
            ) : (
              renderContentInput(true)
            )}
          </main>
        </div>
      ) : (
        renderContentInput()
      )}
    </>
  );
};

export default MyReservationPage;
