import { Tab } from "@headlessui/react";
import { ChangeEvent, FC, Fragment, useState } from "react";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useFetchSingleListing from "hooks/useFetchSingleListing";
import useFetchListingPrice from "hooks/useFetchListingPrice";
import Loader from "components/Loader/Loader";

import "react-loading-skeleton/dist/skeleton.css";
import { calculateDaysDifference, formatDate } from "utils/helperFunctions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useFetchListingPriceV2 from "hooks/useFetchListingPriceV2";
import useFetchSingleListingV2 from "hooks/useFetchSingleListingV2";

export interface CheckOutPagePageMainProps {
  className?: string;
}

interface FormData {
  fullName: string;
  email: string;
  phone: string;
  note: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  //#region

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Access individual query parameters
  const incomingGuests: string | undefined = Array.isArray(queryParams.guests)
    ? queryParams.guests.join(",")
    : queryParams.guests || "";

  const paymentStatus: string | undefined = Array.isArray(queryParams.payment)
    ? queryParams.payment.join(",")
    : queryParams.payment || "";

  const listingId: string | undefined = Array.isArray(queryParams.listing)
    ? queryParams.listing.join(",")
    : queryParams.listing || "";

  const startDateString: string | undefined =
    queryParams.start_date instanceof Array
      ? queryParams.start_date[0] || undefined
      : queryParams.start_date || undefined;

  const endDateString: string | undefined =
    queryParams.end_date instanceof Array
      ? queryParams.end_date[0] || undefined
      : queryParams.end_date || undefined;

  const incomingStartDate: Date | null = startDateString
    ? new Date(startDateString)
    : null;
  const incomingEndDate: Date | null = endDateString
    ? new Date(endDateString)
    : null;

  //#endregion

  const [startDate] = useState<Date | null>(incomingStartDate);
  const [endDate] = useState<Date | null>(incomingEndDate);
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [paymentLinkLoading, setPaymentLinkLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    phone: "",
    note: "",
  });

  const [guests] = useState<GuestsObject>({
    guestAdults: parseInt(incomingGuests),
    guestChildren: 0,
    guestInfants: 0,
  });

  const {
    data: listingData,
    isLoading: listingIsLoading,
    error: listingError,
  } = useFetchSingleListingV2(listingId);

  const {
    data: listingPrice,
    isLoading: listingPriceIsLoading,
    error: listingPriceError,
  } = useFetchListingPriceV2(listingId, startDate, endDate, guests.guestAdults);

  const handleSubmit = async () => {
    try {
      setPaymentLinkLoading(true);
      const apiUrl = `https://allianceventura.com/check.php?listing_id=${
        listingData?._id
      }&start_date=${formatDate(startDate)}&end_date=${formatDate(
        endDate
      )}&name=${listingData?.title}&nights=${calculateDaysDifference(
        formatDate(startDate),
        formatDate(endDate)
      )}&thumbnail=${listingData?.picture.large}&guests=${guests.guestAdults}&first_name=${
        encodeURIComponent(formData.fullName) // Change to formData.firstName
      }&last_name=${encodeURIComponent(
        formData.fullName
      )}&email=${encodeURIComponent(formData.email)}&phone=${encodeURIComponent(
        formData.phone
      )}&additional_notes=${encodeURIComponent(formData.note)}`;

      const response = await fetch(apiUrl, { method: "GET" });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const paymentLink = data.payment_link;

      setPaymentLink(paymentLink);

      setPaymentLinkLoading(false);
      window.location.href = paymentLink;
      // Use stripe and elements to handle payment submission
    } catch (error: any) {
      console.error("Error in handleSubmit:", error.message);
      // Handle the error, e.g., show an error message to the user
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // if (formData.fullName && formData.email && formData.phone) {
    //   handleSubmit();
    // }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          {listingIsLoading ? (
            <>
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <Skeleton width={200} height={200} />
                </div>
              </div>
              <div className="py-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    <Skeleton width={200} />
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    <Skeleton count={2} width={300} />
                  </span>
                </div>
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                  <Skeleton width={50} />
                </span>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                <Skeleton width={50} />
              </div>
            </>
          ) : (
            <>
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <img
                    alt=""
                    className="absolute inset-0 object-cover"
                    sizes="200px"
                    src={listingData?.picture.large}
                  />
                </div>
              </div>
              <div className="py-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    {listingData?.address.neighborhood},{" "}
                    {listingData?.address.city}
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {listingData?.title}
                  </span>
                </div>
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                  {listingData?.bedrooms}{" "}
                  {listingData?.bedrooms === 1 ? "bed" : "beds"} ·{" "}
                  {listingData?.bathrooms}{" "}
                  {listingData?.bathrooms === 1 ? "bath" : "baths"}
                </span>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                {/* <StartRating
                  point={listingData?.rating.rating}
                  reviewCount={listingData?.rating.reviews}
                /> */}
              </div>
            </>
          )}
        </div>

        {listingPriceIsLoading && (
          <>
            <div className="flex flex-col space-y-4">
              <h3 className="text-2xl font-semibold">
                {" "}
                <Skeleton width={200} />
              </h3>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>
                  <Skeleton width={100} />
                </span>
                <span>
                  <Skeleton width={100} />
                </span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>
                  <Skeleton width={100} />
                </span>
                <span>
                  <Skeleton width={100} />
                </span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>
                  <Skeleton width={100} />
                </span>
                <span>
                  <Skeleton width={100} />
                </span>
              </div>
            </div>
          </>
        )}

        {listingPrice &&
        listingPrice.money.balanceDue &&
        listingPrice.money.balanceDue !== undefined ? (
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-semibold">Price detail</h3>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                Subtotaal
              </span>
              <span>AED {Math.round(listingPrice.money.balanceDue - listingPrice.money.fareCleaning)}</span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Cleaning charge</span>
              <span>AED {listingPrice.money.fareCleaning}</span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>AED {listingPrice?.money.balanceDue}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleGuestChanges = (value: GuestsObject) => {
    //do nothing
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    // const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    // do nothing for now
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        {paymentStatus && paymentStatus === "failed" ? (
          <span className="bg-red-600 text-white px-3 py-3 tracking-widest text-xs rounded-full z-10">
            Your payment failed or was cancelled. Please try again.
          </span>
        ) : null}
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
            onDateChange={handleDateChange}
              renderChildren={({ openModal }) => (
                <button
                  //onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([startDate, endDate])}
                    </span>
                  </div>
                  {/* <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />

            <ModalSelectGuests
              onGuestChange={handleGuestChanges}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  //onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {/* {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests, ${guests.guestInfants || 0} Infants`} */}

                        {`${guests.guestAdults || 0} Guests`}
                      </span>
                    </span>
                  </div>
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <form>
            <h3 className="text-2xl font-semibold">Personal Information</h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

            <div className="mt-6">
              <Tab.Group>
                <Tab.List className="flex my-5 gap-1">
                  <Tab as={Fragment}>{({ selected }) => <></>}</Tab>
                </Tab.List>

                <Tab.Panels>
                  <Tab.Panel className="space-y-5">
                    <div className="space-y-1">
                      <Label>Firstname and Lastname* </Label>
                      <Input
                        placeholder="John Doe"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        name="fullName"
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>Email* </Label>
                      <Input
                        placeholder="name@example.com"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>Phone* </Label>
                      <Input
                        placeholder="+971-50-1234567"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>Note </Label>
                      <Textarea
                        placeholder="..."
                        value={formData.note}
                        onChange={handleInputChange}
                        name="note"
                      />
                      <span className="text-sm text-neutral-500 block">
                        If you have any special requests you can write them
                        here.
                      </span>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
              <div className="pt-8">
                <ButtonPrimary
                  href={paymentLink}
                  onClick={handleSubmit}
                  disabled={
                    !(formData.fullName && formData.email && formData.phone)
                  }
                  loading={paymentLinkLoading}
                >
                  Confirm and Pay
                </ButtonPrimary>

                <span className="text-sm text-neutral-500 block">
                  <br />
                  If you press this button you are accepting our Terms of use
                  and also our House Policy
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
