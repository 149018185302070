import { Fragment, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";
import Badge from "shared/Badge/Badge";

const ListYourPropertyPage = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Work with us"
          >
            List your stay with Alliance Ventura
          </h1>
          <Badge
            name="Go to revenue estimation tool"
            href="https://allianceventura.com/calculator.php"
          />
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            As the property owner, you have the opportunity to generate income
            through guests staying at your premises. Your earnings may fluctuate
            based on factors such as seasonality, pricing strategies, and
            occupancy rates. It's important to note that guests typically pay
            rates higher than the standard long-term rental prices because your
            property is fully equipped for their convenience, featuring complete
            interiors, functional utilities, and seamless move-in and move-out
            processes.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };
  let [categories] = useState([
    "Check-in & key exchange",
    "Communication with your guests",
    "Luxury-grade amenities",
    "Concierge-style checkout",
  ]);
  let [categories2] = useState([
    "Professional photography",
    "Guest screening",
    "Professional housekeeping",
    "Property maintenance",
    "Listing and price optimisation",
  ]);

  const handleClick = () => {
    window.open("https://allianceventura.com/calculator.php", "_blank");
  };
  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h2>
          An enjoyable and memorable experience <br />
          awaits your guests
        </h2>

        <div>
          <Tab.Group>
            <Tab.List className="flex flex-col">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`w-full text-left block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-lg  focus:outline-none ${
                        selected
                          ? "bg-primary-500 text-white "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <p>
                  Make your guests’ arrival effortless. We will organise a
                  smooth and seamless check-in for every guest and will be
                  available to assist them throughout their stay. Happy guests,
                  always.
                </p>
              </Tab.Panel>
              <Tab.Panel className="">
                <p>
                  When your communication excels, your business thrives.
                  Starting from the initial interaction to continuous on-site
                  assistance, we ensure your guests are content and your
                  property remains secure.
                </p>
              </Tab.Panel>
              <Tab.Panel className="">
                <p>
                  Indulgence always brings joy. Elevate the ordinary Airbnb stay
                  with high-end toiletries for your guests. Anticipate sheer
                  satisfaction. Immaculate bedding, pristine linen sheets, and
                  plush towels are vital for a restful slumber. We supply
                  top-tier hotel-quality linens for added comfort. Earn rave
                  reviews from guests who wake up refreshed and rejuvenated.
                </p>
              </Tab.Panel>
              <Tab.Panel className="">
                <p>
                  We'll lead your guests through the checkout procedure,
                  guaranteeing the security of your property, confirming all
                  doors and windows are locked, and collecting keys. A
                  hassle-free experience for both you and your guests.
                </p>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        <h3>We handle everything so you can relax</h3>
        <p>
          As the property owner, you have the opportunity to generate income
          through guests staying at your premises. Your earnings may fluctuate
          based on factors such as seasonality, pricing strategies, and
          occupancy rates.
        </p>

        <blockquote>
          <p>
            Managing properties becomes effortless when you choose Alliance
            Ventura.
          </p>
        </blockquote>
        <p>Request your estimate revenue now via our online tool:</p>
        <ButtonPrimary onClick={handleClick}>
          REQUEST ESTIMATE REVENUE
        </ButtonPrimary>
        <p>We handle the following things for you:</p>
        <Tab.Group>
          <Tab.List className="flex flex-col">
            {categories2.map((item) => (
              <Tab key={item} as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`w-full text-left block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-lg  focus:outline-none ${
                      selected
                        ? "bg-primary-500 text-white "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    {item}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="">
              <p>
                A single image can convey volumes. Allow our skilled real estate
                photographers to encapsulate your property in its finest
                splendor. Expect photographs that rival magazine covers.
              </p>
            </Tab.Panel>
            <Tab.Panel className="">
              <p>
                With Alliance Ventura, you can relax with confidence, knowing
                that your home is well taken care of. We offer you peace of mind
                by meticulously evaluating prospective guests, ensuring that
                only the most reliable individuals occupy your property.
              </p>
            </Tab.Panel>
            <Tab.Panel className="">
              <p>
                Empowered with our professional team of experienced hotel
                housekeepers, we will bring a touch of home to your property. An
                impeccably clean place, everytime, for pure happiness.
              </p>
            </Tab.Panel>
            <Tab.Panel className="">
              <p>
                From changing a lightbulb to on-call support, we’ll take care of
                basic property maintenance tasks so you don’t have to. No more
                worrying about the little things.
              </p>
            </Tab.Panel>
            <Tab.Panel className="">
              <p>
                Showcase your property’s best features. Our team of hospitality
                experts will craft the perfect listing for your apartment,
                enlightening all the best features with eye-catching copy and a
                standout title.
              </p>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <h2>Discover how to maximise returns.</h2>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="/list-your-property"
        >
          Research the Area
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="/list-your-property"
        >
          Make certain your property exudes top-tier design
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="/list-your-property"
        >
          Make certain the listing is thoughtfully written
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="/list-your-property"
        >
          Ensure property is clean and well-maintained
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="/list-your-property"
        >
          Ensure property is well-priced
        </a>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>List your property - Alliance Ventura</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.unsplash.com/photo-1527244801394-a5594462a962?q=80&w=2051&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">
            Boost your income with the help of our cutting-edge technology.
          </h2>
          <p>
            Our advanced technological solutions embody years of expertise in
            the hospitality industry, ensuring effortless handling of intricate
            responsibilities associated with short and medium-term rentals. We
            persistently pioneer, streamline, and enhance procedures.
          </p>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            <div
              key={1}
              className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group bg-slate-500"
            >
              <div>
                <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
              </div>
              <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                <h2 className="block text-lg font-semibold text-white ">
                  <span className="line-clamp-2">The Alliance Ventura app</span>
                </h2>

                <span className=" text-neutral-200 hover:text-white font-medium ">
                  Our intuitive application is available on both desktop and
                  mobile devices, placing all the tools you require right at
                  your disposal. Effortlessly oversee your properties and
                  monitor their performance with ease.
                </span>
              </div>
            </div>

            <div
              key={1}
              className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group bg-slate-500"
            >
              <div>
                <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
              </div>
              <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                <h2 className="block text-lg font-semibold text-white ">
                  <span className="line-clamp-2">Stay in control</span>
                </h2>

                <span className=" text-neutral-200 hover:text-white font-medium ">
                  Utilize our calendar overview to stay informed about your
                  reservations, cleaning schedules, and planned maintenance
                  tasks. If you wish to occupy the property yourself, seamlessly
                  synchronize your personal calendar, block off dates, and
                  request extra cleaning services as needed.
                </span>
              </div>
            </div>

            <div
              key={1}
              className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group bg-slate-500"
            >
              <div>
                <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
              </div>
              <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                <h2 className="block text-lg font-semibold text-white ">
                  <span className="line-clamp-2">
                    Our software and automation
                  </span>
                </h2>

                <span className=" text-neutral-200 hover:text-white font-medium ">
                  Our software empowers us to efficiently oversee every facet of
                  short-term rental management. We've developed an array of
                  tools that automate procedures and enhance your occupancy
                  rates, all while ensuring meticulous financial management.
                </span>
              </div>
            </div>

            <div
              key={1}
              className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group bg-slate-500"
            >
              <div>
                <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
              </div>
              <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                <h2 className="block text-lg font-semibold text-white ">
                  <span className="line-clamp-2">
                    Communication and support
                  </span>
                </h2>

                <span className=" text-neutral-200 hover:text-white font-medium ">
                  As our valued client, you'll be assigned a dedicated account
                  manager who will be at your service to address any inquiries
                  and assist you with the onboarding process for your
                  properties. We're here to provide unwavering support at every
                  stage of your journey.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListYourPropertyPage;
