import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/explore/all",
    name: "Explore Stays",
    isNew: true,
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/explore/business-bay",
        name: "Business Bay",
      },
      {
        id: ncNanoId(),
        href: "/explore/downtown",
        name: "Downtown",
      },
      {
        id: ncNanoId(),
        href: "/explore/dubai-hills",
        name: "Dubai Hills",
      },
      {
        id: ncNanoId(),
        href: "/explore/jvc",
        name: "Jumeirah Village Circle",
      },
      {
        id: ncNanoId(),
        href: "/explore/palm-jumeirah",
        name: "Palm Jumeirah",
      },
      {
        id: ncNanoId(),
        href: "/explore/marina",
        name: "Marina",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/careers",
    name: "Careers",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
    type: "none",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/unique_id_",
  //   name: "Five columns",
  //   type: "megaMenu",
  //   megaMenu: megaMenuDemo,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/listing-stay",
  //   name: "Listing Page",
  //   type: "dropdown",
  //   isNew: true,
  //   children: [
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-stay",
  //       name: "Stay listing",
  //       type: "dropdown",
  //       children: [
  //         { id: ncNanoId(), href: "/listing-stay", name: "Stay page" },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-stay-map",
  //           name: "Stay page (map)",
  //         },
  //         { id: ncNanoId(), href: "/listing-stay-detail", name: "Stay Detail" },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-experiences",
  //       name: "Experiences listing",
  //       type: "dropdown",
  //       children: [
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences",
  //           name: "Experiences page",
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences-map",
  //           name: "Experiences page (map)",
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences-detail",
  //           name: "Experiences Detail",
  //         },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-car",
  //       name: "Cars listing",
  //       type: "dropdown",
  //       children: [
  //         { id: ncNanoId(), href: "/listing-car", name: "Cars page" },
  //         { id: ncNanoId(), href: "/listing-car-map", name: "Cars page (map)" },
  //         { id: ncNanoId(), href: "/listing-car-detail", name: "Car Detail" },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-real-estate",
  //       name: "Real Estate Listings",
  //       isNew: true,
  //       type: "dropdown",
  //       children: [
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-real-estate",
  //           name: "Real Estate Listings",
  //           isNew: true,
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-real-estate-map",
  //           name: "Real Estate Maps",
  //           isNew: true,
  //         },
  //       ],
  //     },
  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-flights",
  //       name: "Flights listing",
  //       isNew: true,
  //     },
  //   ],
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/author",
  //   name: "Templates",
  //   type: "dropdown",
  //   children: templatesChildrenMenus,
  // },

  // {
  //   id: ncNanoId(),
  //   href: "/blog",
  //   name: "Other pages",
  //   type: "dropdown",
  //   children: otherPageChildMenus,
  // },
];
