import MyRouter from "routers/index";
import { QueryClient, QueryClientProvider } from "react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { FaCookie } from "react-icons/fa";
import CookieConsent from "react-cookie-consent";
import { TokenResponse, useBearerToken } from "hooks/useBearerToken";

const queryClient = new QueryClient();

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [token, setToken] = useState<string>('');

  // useEffect(() => {
  //   // Check if dark mode is preferred by the user
  //   const prefersDarkMode = window.matchMedia(
  //     "(prefers-color-scheme: dark)"
  //   ).matches;

  //   // Update the state based on the user's preference
  //   setIsDarkMode(prefersDarkMode);
  // }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('https://allianceventura.com/api/v2/token.php');
        const data: TokenResponse = await response.json();
        setToken(data.access_token);
        const expiresAt = new Date().getTime() + data.expires_in * 1000;
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('expires_at', String(expiresAt));
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    // Check if token is already in localStorage and not expired
    const storedExpiresAt = localStorage.getItem('expires_at');
    const storedToken = localStorage.getItem('token');

    if (storedExpiresAt && storedToken) {
      const expiresAt = Number(storedExpiresAt);
      if (expiresAt > new Date().getTime()) {
        // Token is not expired, use it
        setToken(storedToken);
        return;
      }
    }

    // Fetch token if not in localStorage or expired
    fetchToken();

    // Cleanup function
    return () => { };
  }, [token]);

  return (
    <QueryClientProvider client={queryClient}>
      <SkeletonTheme
        baseColor={isDarkMode ? "#444444" : "#ebebeb"}
        highlightColor={isDarkMode ? "#222222" : "#bebebe"}
      >
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <CookieConsent
            location="bottom"
            buttonText={
              <div className="flex items-center">
                <FaCookie />
                <span className="ml-2">Accept all cookies</span>
              </div>
            }
            cookieName="allianceVenturaCookie"
            style={{
              background: "#fff",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px", // Adjust the width as needed
              height: "400px",
              padding: "20px", // Optional padding for the content
              borderRadius: "8px",
              // Adds rounded corners
              boxSizing: "border-box", // Ensures padding doesn't affect the width
              overflow: "auto", // Adds scroll if the content overflows
              zIndex: 1000, // Ensure it stays on top of other elements
            }}
            buttonStyle={{
              color: "#fff",
              fontSize: "16px",
              backgroundColor: "#041C36",
              textAlign: "center",
              borderRadius: "20px",
              padding: "15px",
              marginLeft: "30px",
            }}
            //disableStyles={true}
            expires={150}
            visible="byCookieValue"
            overlay={true}
          >
            <div className="relative text-sm">
              <div className="absolute inset-0 opacity-30">
                <FaCookie size={140} />
              </div>
              <div className="relative z-10 p-4 text-sky-950">
                <h1 className="text-2xl md:text-4xl font-bold mb-3">
                  We use cookies.
                </h1>
                <p className="text-xs md:text-base">
                  This website uses cookies to improve your user experience. By
                  using cookies we can collect information, remember it, and
                  gain insight into how you use our website. This helps us to
                  optimize the website and give you the best possible user
                  experience.
                </p>
              </div>
            </div>
          </CookieConsent>
          <MyRouter />
        </div>
      </SkeletonTheme>
    </QueryClientProvider>
  );
}

export default App;
