import { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Heading2 from "components/Heading/Heading2";
import { StayDistrictProps } from "./ListingStayMapPage";
import { useLocation } from "react-router-dom";
import StayCardHSkeletonSkeleton from "components/StayCardH/StayCardHSkeleton";
import { shuffleArray } from "utils/helperFunctions";
import { Listing } from "types/listing.types";
import { useListingsV2 } from "hooks/useFetchListingsV2";

export interface SectionGridHasMapProps {
  districtName: StayDistrictProps | null;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({ districtName }) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [filteredData, setFilteredData] = useState<Listing[] | undefined>([]);
  const [shuffledData, setShuffledData] = useState<Listing[]>([]);

  const { data, isLoading } = useListingsV2();
  const { data: dataRandom, isLoading: isLoadingDataRandom } = useListingsV2();

  const defaultProps = {
    center: {
      lat: 25.0657,
      lng: 55.17128,
    },
    zoom: 11,
  };

  const location = useLocation();

  useEffect(() => {
    if (districtName?.id !== 999) {
      setFilteredData(data?.filter(item => item.address && item.address.neighborhood === districtName?.name));
    } else {
      setFilteredData(data);
    }
  }, [data, districtName]);

  useEffect(() => {
    if (dataRandom) {
      setShuffledData(shuffleArray([...dataRandom]));
    }
  }, [dataRandom]);

  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={`Stays in ${districtName?.name}`}
            count={filteredData?.length.toString()}
            loading={isLoading}
          />

          {filteredData?.length === 0 ? (
            <>
              <div
                className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
                role="alert"
              >
                <div className="flex">
                  <div>
                    <p className="font-bold">
                      No available listings found for {districtName?.name}
                    </p>
                    <p className="text-sm">
                      We are showing other listings around Dubai.
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-8 mt-5">
                {isLoadingDataRandom ? (
                  <>
                    <StayCardHSkeletonSkeleton />
                    <StayCardHSkeletonSkeleton />
                    <StayCardHSkeletonSkeleton />
                  </>
                ) : (
                  shuffledData?.map((item) => (
                    <div
                      key={item._id}
                      onMouseEnter={() => setCurrentHoverID((_) => item._id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <StayCardH data={item} from={true} />
                    </div>
                  ))
                )}
              </div>
            </>
          ) : null}

          <div className="grid grid-cols-1 gap-8">
            {isLoading ? (
              <>
                <StayCardHSkeletonSkeleton />
                <StayCardHSkeletonSkeleton />
                <StayCardHSkeletonSkeleton />
              </>
            ) : (
              filteredData?.map((item) => (
                <div
                  key={item._id}
                  onMouseEnter={() => setCurrentHoverID((_) => item._id)}
                  onMouseLeave={() => setCurrentHoverID((_) => -1)}
                >
                  <StayCardH data={item} from={true} />
                </div>
              ))
            )}
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        <div
          className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
            }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <GoogleMapReact
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              bootstrapURLKeys={{
                key: "AIzaSyDT67CTvWbc26KYhdEmMUkoCoeNtblYQCw",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {filteredData?.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item._id}
                  key={item._id}
                  lat={item.address.lat}
                  lng={item.address.lng}
                  listing={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
