import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  href?: string;
  enabled?: boolean;
}

const ButtonSubmit: FC<Props> = ({ href = "/search", enabled }) => {
  const tooltipText = "Fill in the required fields"; // Change this to your tooltip text

  return (
    enabled ? (
      <Link
        to={href}
        type="button"
        className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
      >
        <span className="mr-3 md:hidden">Search</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </Link>
    ) : (
      <div className="relative">
        <button
          type="button"
          className="h-14 md:h-16 w-full md:w-16 rounded-full bg-gray-300 cursor-not-allowed flex items-center justify-center text-neutral-500"
          disabled
        >
          <span className="mr-3 md:hidden">Search</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
        {/* <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-800 text-white p-2 rounded-md opacity-90">
          {tooltipText}
        </div> */}
      </div>
    )
  );
};

export default ButtonSubmit;
