import Loader from "components/Loader/Loader";
import React from "react";
import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  count?: string;
  dateRange?: string;
  numerOfGuests?: string;
  loading?: boolean;
  totalUsers?: number;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Stays in Dubai",
  subHeading,
  count,
  dateRange,
  numerOfGuests,
  loading,
  totalUsers
}) => {
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {loading ? (
            <Skeleton width={300} />
          ) : (
            <>
              {count && parseInt(count) >= 1 && <>{count} stays {totalUsers && <>({totalUsers})</>}</>}
              {dateRange && (
                <>
                  <span className="mx-2">·</span>
                  {dateRange}
                </>
              )}
              {numerOfGuests && (
                <>
                  <span className="mx-2">·</span>
                  {numerOfGuests}{" "}
                  {parseInt(numerOfGuests) > 1 ? "Guests" : "Guest"}
                </>
              )}
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default Heading2;
