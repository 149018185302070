import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { addMonths } from "date-fns";

interface Block {
  m: boolean;
  r: boolean;
  b: boolean;
  bd: boolean;
  sr: boolean;
  abl: boolean;
  a: boolean;
  bw: boolean;
  o: boolean;
  pt: boolean;
  an: boolean;
}

interface BlockRef {
  listingId: string;
  accountId: string;
  type: string;
  startDate: string;
  endDate: string;
  hours: number;
  updatedAt: string;
  updatedBy: string;
}

interface Day {
  date: string;
  listingId: string;
  currency: string;
  price: number;
  isBasePrice: boolean;
  minNights: number;
  isBaseMinNights: boolean;
  status: string;
  blocks: Block;
  blockRefs: BlockRef[];
  cta: boolean;
  ctd: boolean;
  requestToBook: boolean;
  notDefaultAvailability: boolean;
}

interface Data {
  days: Day[];
}

interface JSONResponse {
  status: number;
  data: Data;
}



export interface StayDatesRangeInputProps {
  className?: string;
  startDateX?: Date;
  endDateX?: Date;
  listingId?: string | undefined;
  onDatesChange?: (startDate: Date | null, endDate: Date | null) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  startDateX,
  endDateX,
  listingId,
  onDatesChange,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    startDateX !== undefined ? startDateX : null
  );

  const [endDate, setEndDate] = useState<Date | null>(
    endDateX !== undefined ? endDateX : null
  );

  const [availableDates, setAvailableDates] = useState<Date[]>([]);

  const fetchCalendarData = async () => {
    try {
      // Construct the API URL with listing_id, start_date, and end_date
      const storedToken = localStorage.getItem('token');
      const apiUrl = `https://allianceventura.com/api/v2/cal.php?listingId=${listingId}&token=${storedToken}`;

      // Fetch data from the API
      const response = await fetch(apiUrl);
      const data: JSONResponse = await response.json();

      // Filter dates based on their status
      const filteredDates = data.data.days
        .filter((date) => date.status === "available")
        .map((date) => new Date(date.date));

      setAvailableDates(filteredDates);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  useEffect(() => {
    fetchCalendarData();
  }, []);

  const onChangeDate = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (onDatesChange) {
        onDatesChange(start, end);
      }
    }
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  let currentDate = new Date();
  let date12MonthsLater = addMonths(currentDate, 12);

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? "shadow-lg" : ""
              }`}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  minDate={new Date()}
                  maxDate={date12MonthsLater}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => {
                    const price = 1;
                    return <DatePickerCustomDay dayOfMonth={day} date={date} />;
                  }}
                  includeDates={availableDates}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
