import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Listing } from "types/listing.types";

export interface StayCardHSkeletonProps {
  className?: string;
  data?: Listing;
  afterPriceTitle?: string;
  from?: boolean;
  params?: any;
}

const StayCardHSkeletonSkeleton: FC<StayCardHSkeletonProps> = ({
  className = "",
}) => {
  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <Skeleton className="aspect-w-6 aspect-h-5" />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <Skeleton count={2} />
        </div>
        <div className="space-y-3">
          <Skeleton count={2} />
        </div>
        <div className="space-y-3">
          <Skeleton count={2} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <Skeleton />
          <Skeleton height={30} />
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>

        <Skeleton height={20} />
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardHSkeleton group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardHSkeleton"
    >
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardHSkeletonSkeleton;
