import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/explore/business-bay",
    name: "Bussiness Bay",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://mayak.ae/wp-content/uploads/2023/07/business-bay.jpg",
  },
  {
    id: "2",
    href: "/explore/downtown",
    name: "Downtown",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://do84cgvgcm805.cloudfront.net/area/41/1180/9844134b4535f22b21656061cfd4b9f7af321a6bb1fb4c136db983f43ea5c30f.jpg",
  },
  {
    id: "2",
    href: "/explore/dubai-hills",
    name: "Dubai Hills",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.expatwoman.com/s3fs-public/dubai%20hills%20estate%20area%20guide%20-%20main.jpg",
  },
  {
    id: "2",
    href: "/explore/jvc",
    name: "Jumeirah Village Circle",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.arabianbusiness.com/cloud/2022/07/26/Jumeirah-Village-Circle.jpg",
  },
  {
    id: "2",
    href: "/explore/palm-jumeirah",
    name: "Palm Jumeirah",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.visitdubai.com/-/media/images/leisure/neighbourhood-pages/palm-jumeirah/media/palm-jumeirah-media-3-dtcm.jpg",
    // "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/explore/marina",
    name: "Marina",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.timeoutdubai.com/cloud/timeoutdubai/2022/07/04/dubai-marina.jpg",
  },
];

const PLACES_TO_VISIT: TaxonomyType[] = [
  {
    id: "1",
    href: "https://www.visitdubai.com/en/places-to-visit/burj-khalifa",
    name: "Burj Khalifa",
    taxonomy: "category",
    count: 0,
    desc: "Standing 828m high, the Burj Khalifa is the tallest building in the world and one of Dubai's must-visit attractions. The majestic building is located in the heart of the city and is a hub of activity day and night.",
    thumbnail:
      "https://www.visitdubai.com/-/media/gathercontent/poi/b/burj-khalifa/fallback-image/poi-burj-khalifa-3-dtcm-jun-2023.jpg?rev=415589767c9a4f2abb4edde4306182ab&cx=0.48&cy=0.38&cw=1180&ch=590",
  },
  {
    id: "222",
    href: "https://www.visitdubai.com/en/places-to-visit/dubai-mall",
    name: "Dubai Mall",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.visitdubai.com/-/media/gathercontent/poi/t/the-dubai-mall/fallback-image/the-dubai-mall-poi-shutterstock.jpg?rev=c5e9c4ffcdee4145b29d839227896dc2&cx=0.64&cy=0.38&cw=1180&ch=590",
  },
  {
    id: "3",
    href: "https://www.visitdubai.com/en/places-to-visit/al-fahidi-historical-neighbourhood",
    name: "Al Fahidi Historical Neighbourhood",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.visitdubai.com/-/media/gathercontent/article/t/top-things-to-do-in-dubai/media/top-things-to-do-in-dubai-al-fahidi-10.jpg?rev=0085072c4aeb4f1b8eed7ab6dae514ad&cx=0.5&cy=0.5&cw=1180&ch=590",
  },
  {
    id: "4",
    href: "https://www.visitdubai.com/en/places-to-visit/museum-of-the-future",
    name: "Museum of the Future",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.visitdubai.com/-/media/gathercontent/poi/m/museum-of-the-future/fallback-image/museum-of-the-future-04-supplied.jpg?rev=0808e691c27147c9907cbde766f95549&cx=0.46&cy=0.2&cw=1556&ch=690",
  },
  {
    id: "5",
    href: "https://www.visitdubai.com/en/places-to-visit/aquaventure",
    name: "Aquaventure Waterpark",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.visitdubai.com/-/media/gathercontent/poi/a/aquaventure/fallback-image/aquaventure-waterpark-poi-dtcm.jpg?rev=740bf5d32a0b4a8da421a445ee980c15&cx=0.79&cy=0.81&cw=1556&ch=690",
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

        {/* SECTION2 */}
        {/* <SectionOurFeatures /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION */}
        {/* <SectionHowItWork /> */}

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={PLACES_TO_VISIT}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Visit Top Attractions in Dubai"
            subHeading="Popular places to stay that Alliance Ventura recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        <SectionVideos />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
